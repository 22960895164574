const Departamentos = [{
        idDepartamento: "01",
        nombre: "AMAZONAS"
    },
    {
        idDepartamento: "02",
        nombre: "ÁNCASH"
    },
    {
        idDepartamento: "03",
        nombre: "APURÍMAC"
    },
    {
        idDepartamento: "04",
        nombre: "AREQUIPA"
    },
    {
        idDepartamento: "05",
        nombre: "AYACUCHO"
    },
    {
        idDepartamento: "06",
        nombre: "CAJAMARCA"
    },
    {
        idDepartamento: "07",
        nombre: "CALLAO"
    },
    {
        idDepartamento: "08",
        nombre: "CUSCO"
    },
    {
        idDepartamento: "09",
        nombre: "HUANCAVELICA"
    },
    {
        idDepartamento: "10",
        nombre: "HUÁNUCO"
    },
    {
        idDepartamento: "11",
        nombre: "ICA"
    },
    {
        idDepartamento: "12",
        nombre: "JUNÍN"
    },
    {
        idDepartamento: "13",
        nombre: "LA LIBERTAD"
    },
    {
        idDepartamento: "14",
        nombre: "LAMBAYEQUE"
    },
    {
        idDepartamento: "15",
        nombre: "LIMA"
    },
    {
        idDepartamento: "16",
        nombre: "LORETO"
    },
    {
        idDepartamento: "17",
        nombre: "MADRE DE DIOS"
    },
    {
        idDepartamento: "18",
        nombre: "MOQUEGUA"
    },
    {
        idDepartamento: "19",
        nombre: "PASCO"
    },
    {
        idDepartamento: "20",
        nombre: "PIURA"
    },
    {
        idDepartamento: "21",
        nombre: "PUNO"
    },
    {
        idDepartamento: "22",
        nombre: "SAN MARTÍN"
    },
    {
        idDepartamento: "23",
        nombre: "TACNA"
    },
    {
        idDepartamento: "24",
        nombre: "TUMBES"
    },
    {
        idDepartamento: "25",
        nombre: "UCAYALI"
    }
];

const Provincias = [{
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "CHACHAPOYAS"
    },
    {
        idProvincia: "0102",
        idDepartamento: "01",
        nombre: "BAGUA"
    },
    {
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "BONGARÁ"
    },
    {
        idProvincia: "0104",
        idDepartamento: "01",
        nombre: "CONDORCANQUI"
    },
    {
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "LUYA"
    },
    {
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "RODRÍGUEZ DE MENDOZA"
    },
    {
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "UTCUBAMBA"
    },
    {
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "HUARAZ"
    },
    {
        idProvincia: "0202",
        idDepartamento: "02",
        nombre: "AIJA"
    },
    {
        idProvincia: "0203",
        idDepartamento: "02",
        nombre: "ANTONIO RAYMONDI"
    },
    {
        idProvincia: "0204",
        idDepartamento: "02",
        nombre: "ASUNCIÓN"
    },
    {
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "BOLOGNESI"
    },
    {
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "CARHUAZ"
    },
    {
        idProvincia: "0207",
        idDepartamento: "02",
        nombre: "CARLOS FERMÍN FITZCARRALD"
    },
    {
        idProvincia: "0208",
        idDepartamento: "02",
        nombre: "CASMA"
    },
    {
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "CORONGO"
    },
    {
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "HUARI"
    },
    {
        idProvincia: "0211",
        idDepartamento: "02",
        nombre: "HUARMEY"
    },
    {
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "HUAYLAS"
    },
    {
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "MARISCAL LUZURIAGA"
    },
    {
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "OCROS"
    },
    {
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "PALLASCA"
    },
    {
        idProvincia: "0216",
        idDepartamento: "02",
        nombre: "POMABAMBA"
    },
    {
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "RECUAY"
    },
    {
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "SANTA"
    },
    {
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "SIHUAS"
    },
    {
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "YUNGAY"
    },
    {
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "ABANCAY"
    },
    {
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "ANDAHUAYLAS"
    },
    {
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "ANTABAMBA"
    },
    {
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "AYMARAES"
    },
    {
        idProvincia: "0305",
        idDepartamento: "03",
        nombre: "COTABAMBAS"
    },
    {
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "CHINCHEROS"
    },
    {
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "GRAU"
    },
    {
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "AREQUIPA"
    },
    {
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "CAMANÁ"
    },
    {
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "CARAVELÍ"
    },
    {
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "CASTILLA"
    },
    {
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "CAYLLOMA"
    },
    {
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "CONDESUYOS"
    },
    {
        idProvincia: "0407",
        idDepartamento: "04",
        nombre: "ISLAY"
    },
    {
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "LA UNIÒN"
    },
    {
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "HUAMANGA"
    },
    {
        idProvincia: "0502",
        idDepartamento: "05",
        nombre: "CANGALLO"
    },
    {
        idProvincia: "0503",
        idDepartamento: "05",
        nombre: "HUANCA SANCOS"
    },
    {
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "HUANTA"
    },
    {
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "LA MAR"
    },
    {
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "LUCANAS"
    },
    {
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "PARINACOCHAS"
    },
    {
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "PÀUCAR DEL SARA SARA"
    },
    {
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "SUCRE"
    },
    {
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "VÍCTOR FAJARDO"
    },
    {
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "VILCAS HUAMÁN"
    },
    {
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "CAJAMARCA"
    },
    {
        idProvincia: "0602",
        idDepartamento: "06",
        nombre: "CAJABAMBA"
    },
    {
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "CELENDÍN"
    },
    {
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CHOTA"
    },
    {
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "CONTUMAZÁ"
    },
    {
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "CUTERVO"
    },
    {
        idProvincia: "0607",
        idDepartamento: "06",
        nombre: "HUALGAYOC"
    },
    {
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "JAÉN"
    },
    {
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "SAN IGNACIO"
    },
    {
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "SAN MARCOS"
    },
    {
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "SAN MIGUEL"
    },
    {
        idProvincia: "0612",
        idDepartamento: "06",
        nombre: "SAN PABLO"
    },
    {
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "SANTA CRUZ"
    },
    {
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "PROV. CONST. DEL CALLAO"
    },
    {
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "CUSCO"
    },
    {
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "ACOMAYO"
    },
    {
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "ANTA"
    },
    {
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "CALCA"
    },
    {
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "CANAS"
    },
    {
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "CANCHIS"
    },
    {
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "CHUMBIVILCAS"
    },
    {
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "ESPINAR"
    },
    {
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "LA CONVENCIÓN"
    },
    {
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "PARURO"
    },
    {
        idProvincia: "0811",
        idDepartamento: "08",
        nombre: "PAUCARTAMBO"
    },
    {
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "QUISPICANCHI"
    },
    {
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "URUBAMBA"
    },
    {
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "HUANCAVELICA"
    },
    {
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "ACOBAMBA"
    },
    {
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "ANGARAES"
    },
    {
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "CASTROVIRREYNA"
    },
    {
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "CHURCAMPA"
    },
    {
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "HUAYTARÁ"
    },
    {
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "TAYACAJA"
    },
    {
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "HUÁNUCO"
    },
    {
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "AMBO"
    },
    {
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "DOS DE MAYO"
    },
    {
        idProvincia: "1004",
        idDepartamento: "10",
        nombre: "HUACAYBAMBA"
    },
    {
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "HUAMALÍES"
    },
    {
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "LEONCIO PRADO"
    },
    {
        idProvincia: "1007",
        idDepartamento: "10",
        nombre: "MARAÑÓN"
    },
    {
        idProvincia: "1008",
        idDepartamento: "10",
        nombre: "PACHITEA"
    },
    {
        idProvincia: "1009",
        idDepartamento: "10",
        nombre: "PUERTO INCA"
    },
    {
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "LAURICOCHA "
    },
    {
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "YAROWILCA "
    },
    {
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "ICA "
    },
    {
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "CHINCHA "
    },
    {
        idProvincia: "1103",
        idDepartamento: "11",
        nombre: "NASCA "
    },
    {
        idProvincia: "1104",
        idDepartamento: "11",
        nombre: "PALPA "
    },
    {
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "PISCO "
    },
    {
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "HUANCAYO "
    },
    {
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "CONCEPCIÓN "
    },
    {
        idProvincia: "1203",
        idDepartamento: "12",
        nombre: "CHANCHAMAYO "
    },
    {
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "JAUJA "
    },
    {
        idProvincia: "1205",
        idDepartamento: "12",
        nombre: "JUNÍN "
    },
    {
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "SATIPO "
    },
    {
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "TARMA "
    },
    {
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "YAULI "
    },
    {
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "CHUPACA "
    },
    {
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "TRUJILLO "
    },
    {
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "ASCOPE "
    },
    {
        idProvincia: "1303",
        idDepartamento: "13",
        nombre: "BOLÍVAR "
    },
    {
        idProvincia: "1304",
        idDepartamento: "13",
        nombre: "CHEPÉN "
    },
    {
        idProvincia: "1305",
        idDepartamento: "13",
        nombre: "JULCÁN "
    },
    {
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "OTUZCO "
    },
    {
        idProvincia: "1307",
        idDepartamento: "13",
        nombre: "PACASMAYO "
    },
    {
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "PATAZ "
    },
    {
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "SÁNCHEZ CARRIÓN "
    },
    {
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "SANTIAGO DE CHUCO "
    },
    {
        idProvincia: "1311",
        idDepartamento: "13",
        nombre: "GRAN CHIMÚ "
    },
    {
        idProvincia: "1312",
        idDepartamento: "13",
        nombre: "VIRÚ "
    },
    {
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "CHICLAYO "
    },
    {
        idProvincia: "1402",
        idDepartamento: "14",
        nombre: "FERREÑAFE "
    },
    {
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "LAMBAYEQUE "
    },
    {
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LIMA "
    },
    {
        idProvincia: "1502",
        idDepartamento: "15",
        nombre: "BARRANCA "
    },
    {
        idProvincia: "1503",
        idDepartamento: "15",
        nombre: "CAJATAMBO "
    },
    {
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "CANTA "
    },
    {
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "CAÑETE "
    },
    {
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "HUARAL "
    },
    {
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "HUAROCHIRÍ "
    },
    {
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "HUAURA "
    },
    {
        idProvincia: "1509",
        idDepartamento: "15",
        nombre: "OYÓN "
    },
    {
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "YAUYOS "
    },
    {
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "MAYNAS "
    },
    {
        idProvincia: "1602",
        idDepartamento: "16",
        nombre: "ALTO AMAZONAS "
    },
    {
        idProvincia: "1603",
        idDepartamento: "16",
        nombre: "LORETO "
    },
    {
        idProvincia: "1604",
        idDepartamento: "16",
        nombre: "MARISCAL RAMÓN CASTILLA "
    },
    {
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "REQUENA "
    },
    {
        idProvincia: "1606",
        idDepartamento: "16",
        nombre: "UCAYALI "
    },
    {
        idProvincia: "1607",
        idDepartamento: "16",
        nombre: "DATEM DEL MARAÑÓN "
    },
    {
        idProvincia: "1608",
        idDepartamento: "16",
        nombre: "PUTUMAYO"
    },
    {
        idProvincia: "1701",
        idDepartamento: "17",
        nombre: "TAMBOPATA "
    },
    {
        idProvincia: "1702",
        idDepartamento: "17",
        nombre: "MANU "
    },
    {
        idProvincia: "1703",
        idDepartamento: "17",
        nombre: "TAHUAMANU "
    },
    {
        idProvincia: "1801",
        idDepartamento: "18",
        nombre: "MARISCAL NIETO "
    },
    {
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "GENERAL SÁNCHEZ CERRO "
    },
    {
        idProvincia: "1803",
        idDepartamento: "18",
        nombre: "ILO "
    },
    {
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "PASCO "
    },
    {
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "DANIEL ALCIDES CARRIÓN "
    },
    {
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "OXAPAMPA "
    },
    {
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "PIURA "
    },
    {
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "AYABACA "
    },
    {
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "HUANCABAMBA "
    },
    {
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "MORROPÓN "
    },
    {
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "PAITA "
    },
    {
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "SULLANA "
    },
    {
        idProvincia: "2007",
        idDepartamento: "20",
        nombre: "TALARA "
    },
    {
        idProvincia: "2008",
        idDepartamento: "20",
        nombre: "SECHURA "
    },
    {
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "PUNO "
    },
    {
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "AZÁNGARO "
    },
    {
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "CARABAYA "
    },
    {
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "CHUCUITO "
    },
    {
        idProvincia: "2105",
        idDepartamento: "21",
        nombre: "EL COLLAO "
    },
    {
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "HUANCANÉ "
    },
    {
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "LAMPA "
    },
    {
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "MELGAR "
    },
    {
        idProvincia: "2109",
        idDepartamento: "21",
        nombre: "MOHO "
    },
    {
        idProvincia: "2110",
        idDepartamento: "21",
        nombre: "SAN ANTONIO DE PUTINA "
    },
    {
        idProvincia: "2111",
        idDepartamento: "21",
        nombre: "SAN ROMÁN "
    },
    {
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "SANDIA "
    },
    {
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "YUNGUYO "
    },
    {
        idProvincia: "2201",
        idDepartamento: "22",
        nombre: "MOYOBAMBA "
    },
    {
        idProvincia: "2202",
        idDepartamento: "22",
        nombre: "BELLAVISTA "
    },
    {
        idProvincia: "2203",
        idDepartamento: "22",
        nombre: "EL DORADO "
    },
    {
        idProvincia: "2204",
        idDepartamento: "22",
        nombre: "HUALLAGA "
    },
    {
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "LAMAS "
    },
    {
        idProvincia: "2206",
        idDepartamento: "22",
        nombre: "MARISCAL CÁCERES "
    },
    {
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "PICOTA "
    },
    {
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "RIOJA "
    },
    {
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "SAN MARTÍN "
    },
    {
        idProvincia: "2210",
        idDepartamento: "22",
        nombre: "TOCACHE "
    },
    {
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "TACNA "
    },
    {
        idProvincia: "2302",
        idDepartamento: "23",
        nombre: "CANDARAVE "
    },
    {
        idProvincia: "2303",
        idDepartamento: "23",
        nombre: "JORGE BASADRE "
    },
    {
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "TARATA "
    },
    {
        idProvincia: "2401",
        idDepartamento: "24",
        nombre: "TUMBES "
    },
    {
        idProvincia: "2402",
        idDepartamento: "24",
        nombre: "CONTRALMIRANTE VILLAR "
    },
    {
        idProvincia: "2403",
        idDepartamento: "24",
        nombre: "ZARUMILLA "
    },
    {
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "CORONEL PORTILLO "
    },
    {
        idProvincia: "2502",
        idDepartamento: "25",
        nombre: "ATALAYA "
    },
    {
        idProvincia: "2503",
        idDepartamento: "25",
        nombre: "PADRE ABAD "
    },
    {
        idProvincia: "2504",
        idDepartamento: "25",
        nombre: "PURÚS"
    }
];

const Distritos = [{
        idDistrito: "010101",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "CHACHAPOYAS"
    },
    {
        idDistrito: "010102",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "ASUNCIÓN"
    },
    {
        idDistrito: "010103",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "BALSAS"
    },
    {
        idDistrito: "010104",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "CHETO"
    },
    {
        idDistrito: "010105",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "CHILIQUIN"
    },
    {
        idDistrito: "010106",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "CHUQUIBAMBA"
    },
    {
        idDistrito: "010107",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "GRANADA"
    },
    {
        idDistrito: "010108",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "HUANCAS"
    },
    {
        idDistrito: "010109",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "LA JALCA"
    },
    {
        idDistrito: "010110",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "LEIMEBAMBA"
    },
    {
        idDistrito: "010111",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "LEVANTO"
    },
    {
        idDistrito: "010112",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "MAGDALENA"
    },
    {
        idDistrito: "010113",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "MARISCAL CASTILLA"
    },
    {
        idDistrito: "010114",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "MOLINOPAMPA"
    },
    {
        idDistrito: "010115",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "MONTEVIDEO"
    },
    {
        idDistrito: "010116",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "OLLEROS"
    },
    {
        idDistrito: "010117",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "QUINJALCA"
    },
    {
        idDistrito: "010118",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "SAN FRANCISCO DE DAGUAS"
    },
    {
        idDistrito: "010119",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "SAN ISIDRO DE MAINO"
    },
    {
        idDistrito: "010120",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "SOLOCO"
    },
    {
        idDistrito: "010121",
        idProvincia: "0101",
        idDepartamento: "01",
        nombre: "SONCHE"
    },
    {
        idDistrito: "010201",
        idProvincia: "0102",
        idDepartamento: "01",
        nombre: "BAGUA"
    },
    {
        idDistrito: "010202",
        idProvincia: "0102",
        idDepartamento: "01",
        nombre: "ARAMANGO"
    },
    {
        idDistrito: "010203",
        idProvincia: "0102",
        idDepartamento: "01",
        nombre: "COPALLIN"
    },
    {
        idDistrito: "010204",
        idProvincia: "0102",
        idDepartamento: "01",
        nombre: "EL PARCO"
    },
    {
        idDistrito: "010205",
        idProvincia: "0102",
        idDepartamento: "01",
        nombre: "IMAZA"
    },
    {
        idDistrito: "010206",
        idProvincia: "0102",
        idDepartamento: "01",
        nombre: "LA PECA"
    },
    {
        idDistrito: "010301",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "JUMBILLA"
    },
    {
        idDistrito: "010302",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "CHISQUILLA"
    },
    {
        idDistrito: "010303",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "CHURUJA"
    },
    {
        idDistrito: "010304",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "COROSHA"
    },
    {
        idDistrito: "010305",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "CUISPES"
    },
    {
        idDistrito: "010306",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "FLORIDA"
    },
    {
        idDistrito: "010307",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "JAZAN"
    },
    {
        idDistrito: "010308",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "RECTA"
    },
    {
        idDistrito: "010309",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "SAN CARLOS"
    },
    {
        idDistrito: "010310",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "SHIPASBAMBA"
    },
    {
        idDistrito: "010311",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "VALERA"
    },
    {
        idDistrito: "010312",
        idProvincia: "0103",
        idDepartamento: "01",
        nombre: "YAMBRASBAMBA"
    },
    {
        idDistrito: "010401",
        idProvincia: "0104",
        idDepartamento: "01",
        nombre: "NIEVA"
    },
    {
        idDistrito: "010402",
        idProvincia: "0104",
        idDepartamento: "01",
        nombre: "EL CENEPA"
    },
    {
        idDistrito: "010403",
        idProvincia: "0104",
        idDepartamento: "01",
        nombre: "RÍO SANTIAGO"
    },
    {
        idDistrito: "010501",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "LAMUD"
    },
    {
        idDistrito: "010502",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "CAMPORREDONDO"
    },
    {
        idDistrito: "010503",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "COCABAMBA"
    },
    {
        idDistrito: "010504",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "COLCAMAR"
    },
    {
        idDistrito: "010505",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "CONILA"
    },
    {
        idDistrito: "010506",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "INGUILPATA"
    },
    {
        idDistrito: "010507",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "LONGUITA"
    },
    {
        idDistrito: "010508",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "LONYA CHICO"
    },
    {
        idDistrito: "010509",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "LUYA"
    },
    {
        idDistrito: "010510",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "LUYA VIEJO"
    },
    {
        idDistrito: "010511",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "MARÍA"
    },
    {
        idDistrito: "010512",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "OCALLI"
    },
    {
        idDistrito: "010513",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "OCUMAL"
    },
    {
        idDistrito: "010514",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "PISUQUIA"
    },
    {
        idDistrito: "010515",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "PROVIDENCIA"
    },
    {
        idDistrito: "010516",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "SAN CRISTÓBAL"
    },
    {
        idDistrito: "010517",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "SAN FRANCISCO DE YESO"
    },
    {
        idDistrito: "010518",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "SAN JERÓNIMO"
    },
    {
        idDistrito: "010519",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "SAN JUAN DE LOPECANCHA"
    },
    {
        idDistrito: "010520",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "SANTA CATALINA"
    },
    {
        idDistrito: "010521",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "SANTO TOMAS"
    },
    {
        idDistrito: "010522",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "TINGO"
    },
    {
        idDistrito: "010523",
        idProvincia: "0105",
        idDepartamento: "01",
        nombre: "TRITA"
    },
    {
        idDistrito: "010601",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "SAN NICOLÁS"
    },
    {
        idDistrito: "010602",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "CHIRIMOTO"
    },
    {
        idDistrito: "010603",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "COCHAMAL"
    },
    {
        idDistrito: "010604",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "HUAMBO"
    },
    {
        idDistrito: "010605",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "LIMABAMBA"
    },
    {
        idDistrito: "010606",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "LONGAR"
    },
    {
        idDistrito: "010607",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "MARISCAL BENAVIDES"
    },
    {
        idDistrito: "010608",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "MILPUC"
    },
    {
        idDistrito: "010609",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "OMIA"
    },
    {
        idDistrito: "010610",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "010611",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "TOTORA"
    },
    {
        idDistrito: "010612",
        idProvincia: "0106",
        idDepartamento: "01",
        nombre: "VISTA ALEGRE"
    },
    {
        idDistrito: "010701",
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "BAGUA GRANDE"
    },
    {
        idDistrito: "010702",
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "CAJARURO"
    },
    {
        idDistrito: "010703",
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "CUMBA"
    },
    {
        idDistrito: "010704",
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "EL MILAGRO"
    },
    {
        idDistrito: "010705",
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "JAMALCA"
    },
    {
        idDistrito: "010706",
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "LONYA GRANDE"
    },
    {
        idDistrito: "010707",
        idProvincia: "0107",
        idDepartamento: "01",
        nombre: "YAMON"
    },
    {
        idDistrito: "020101",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "HUARAZ"
    },
    {
        idDistrito: "020102",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "COCHABAMBA"
    },
    {
        idDistrito: "020103",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "COLCABAMBA"
    },
    {
        idDistrito: "020104",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "HUANCHAY"
    },
    {
        idDistrito: "020105",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "INDEPENDENCIA"
    },
    {
        idDistrito: "020106",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "JANGAS"
    },
    {
        idDistrito: "020107",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "LA LIBERTAD"
    },
    {
        idDistrito: "020108",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "OLLEROS"
    },
    {
        idDistrito: "020109",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "PAMPAS GRANDE"
    },
    {
        idDistrito: "020110",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "PARIACOTO"
    },
    {
        idDistrito: "020111",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "PIRA"
    },
    {
        idDistrito: "020112",
        idProvincia: "0201",
        idDepartamento: "02",
        nombre: "TARICA"
    },
    {
        idDistrito: "020201",
        idProvincia: "0202",
        idDepartamento: "02",
        nombre: "AIJA"
    },
    {
        idDistrito: "020202",
        idProvincia: "0202",
        idDepartamento: "02",
        nombre: "CORIS"
    },
    {
        idDistrito: "020203",
        idProvincia: "0202",
        idDepartamento: "02",
        nombre: "HUACLLAN"
    },
    {
        idDistrito: "020204",
        idProvincia: "0202",
        idDepartamento: "02",
        nombre: "LA MERCED"
    },
    {
        idDistrito: "020205",
        idProvincia: "0202",
        idDepartamento: "02",
        nombre: "SUCCHA"
    },
    {
        idDistrito: "020301",
        idProvincia: "0203",
        idDepartamento: "02",
        nombre: "LLAMELLIN"
    },
    {
        idDistrito: "020302",
        idProvincia: "0203",
        idDepartamento: "02",
        nombre: "ACZO"
    },
    {
        idDistrito: "020303",
        idProvincia: "0203",
        idDepartamento: "02",
        nombre: "CHACCHO"
    },
    {
        idDistrito: "020304",
        idProvincia: "0203",
        idDepartamento: "02",
        nombre: "CHINGAS"
    },
    {
        idDistrito: "020305",
        idProvincia: "0203",
        idDepartamento: "02",
        nombre: "MIRGAS"
    },
    {
        idDistrito: "020306",
        idProvincia: "0203",
        idDepartamento: "02",
        nombre: "SAN JUAN DE RONTOY"
    },
    {
        idDistrito: "020401",
        idProvincia: "0204",
        idDepartamento: "02",
        nombre: "CHACAS"
    },
    {
        idDistrito: "020402",
        idProvincia: "0204",
        idDepartamento: "02",
        nombre: "ACOCHACA"
    },
    {
        idDistrito: "020501",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "CHIQUIAN"
    },
    {
        idDistrito: "020502",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "ABELARDO PARDO LEZAMETA"
    },
    {
        idDistrito: "020503",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "ANTONIO RAYMONDI"
    },
    {
        idDistrito: "020504",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "AQUIA"
    },
    {
        idDistrito: "020505",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "CAJACAY"
    },
    {
        idDistrito: "020506",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "CANIS"
    },
    {
        idDistrito: "020507",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "COLQUIOC"
    },
    {
        idDistrito: "020508",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "HUALLANCA"
    },
    {
        idDistrito: "020509",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "HUASTA"
    },
    {
        idDistrito: "020510",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "HUAYLLACAYAN"
    },
    {
        idDistrito: "020511",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "LA PRIMAVERA"
    },
    {
        idDistrito: "020512",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "MANGAS"
    },
    {
        idDistrito: "020513",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "PACLLON"
    },
    {
        idDistrito: "020514",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "SAN MIGUEL DE CORPANQUI"
    },
    {
        idDistrito: "020515",
        idProvincia: "0205",
        idDepartamento: "02",
        nombre: "TICLLOS"
    },
    {
        idDistrito: "020601",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "CARHUAZ"
    },
    {
        idDistrito: "020602",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "ACOPAMPA"
    },
    {
        idDistrito: "020603",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "AMASHCA"
    },
    {
        idDistrito: "020604",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "ANTA"
    },
    {
        idDistrito: "020605",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "ATAQUERO"
    },
    {
        idDistrito: "020606",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "MARCARA"
    },
    {
        idDistrito: "020607",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "PARIAHUANCA"
    },
    {
        idDistrito: "020608",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "SAN MIGUEL DE ACO"
    },
    {
        idDistrito: "020609",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "SHILLA"
    },
    {
        idDistrito: "020610",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "TINCO"
    },
    {
        idDistrito: "020611",
        idProvincia: "0206",
        idDepartamento: "02",
        nombre: "YUNGAR"
    },
    {
        idDistrito: "020701",
        idProvincia: "0207",
        idDepartamento: "02",
        nombre: "SAN LUIS"
    },
    {
        idDistrito: "020702",
        idProvincia: "0207",
        idDepartamento: "02",
        nombre: "SAN NICOLÁS"
    },
    {
        idDistrito: "020703",
        idProvincia: "0207",
        idDepartamento: "02",
        nombre: "YAUYA"
    },
    {
        idDistrito: "020801",
        idProvincia: "0208",
        idDepartamento: "02",
        nombre: "CASMA"
    },
    {
        idDistrito: "020802",
        idProvincia: "0208",
        idDepartamento: "02",
        nombre: "BUENA VISTA ALTA"
    },
    {
        idDistrito: "020803",
        idProvincia: "0208",
        idDepartamento: "02",
        nombre: "COMANDANTE NOEL"
    },
    {
        idDistrito: "020804",
        idProvincia: "0208",
        idDepartamento: "02",
        nombre: "YAUTAN"
    },
    {
        idDistrito: "020901",
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "CORONGO"
    },
    {
        idDistrito: "020902",
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "ACO"
    },
    {
        idDistrito: "020903",
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "BAMBAS"
    },
    {
        idDistrito: "020904",
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "CUSCA"
    },
    {
        idDistrito: "020905",
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "LA PAMPA"
    },
    {
        idDistrito: "020906",
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "YANAC"
    },
    {
        idDistrito: "020907",
        idProvincia: "0209",
        idDepartamento: "02",
        nombre: "YUPAN"
    },
    {
        idDistrito: "021001",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "HUARI"
    },
    {
        idDistrito: "021002",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "ANRA"
    },
    {
        idDistrito: "021003",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "CAJAY"
    },
    {
        idDistrito: "021004",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "CHAVIN DE HUANTAR"
    },
    {
        idDistrito: "021005",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "HUACACHI"
    },
    {
        idDistrito: "021006",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "HUACCHIS"
    },
    {
        idDistrito: "021007",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "HUACHIS"
    },
    {
        idDistrito: "021008",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "HUANTAR"
    },
    {
        idDistrito: "021009",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "MASIN"
    },
    {
        idDistrito: "021010",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "PAUCAS"
    },
    {
        idDistrito: "021011",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "PONTO"
    },
    {
        idDistrito: "021012",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "RAHUAPAMPA"
    },
    {
        idDistrito: "021013",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "RAPAYAN"
    },
    {
        idDistrito: "021014",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "SAN MARCOS"
    },
    {
        idDistrito: "021015",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "SAN PEDRO DE CHANA"
    },
    {
        idDistrito: "021016",
        idProvincia: "0210",
        idDepartamento: "02",
        nombre: "UCO"
    },
    {
        idDistrito: "021101",
        idProvincia: "0211",
        idDepartamento: "02",
        nombre: "HUARMEY"
    },
    {
        idDistrito: "021102",
        idProvincia: "0211",
        idDepartamento: "02",
        nombre: "COCHAPETI"
    },
    {
        idDistrito: "021103",
        idProvincia: "0211",
        idDepartamento: "02",
        nombre: "CULEBRAS"
    },
    {
        idDistrito: "021104",
        idProvincia: "0211",
        idDepartamento: "02",
        nombre: "HUAYAN"
    },
    {
        idDistrito: "021105",
        idProvincia: "0211",
        idDepartamento: "02",
        nombre: "MALVAS"
    },
    {
        idDistrito: "021201",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "CARAZ"
    },
    {
        idDistrito: "021202",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "HUALLANCA"
    },
    {
        idDistrito: "021203",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "HUATA"
    },
    {
        idDistrito: "021204",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "HUAYLAS"
    },
    {
        idDistrito: "021205",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "MATO"
    },
    {
        idDistrito: "021206",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "PAMPAROMAS"
    },
    {
        idDistrito: "021207",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "PUEBLO LIBRE"
    },
    {
        idDistrito: "021208",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "SANTA CRUZ"
    },
    {
        idDistrito: "021209",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "SANTO TORIBIO"
    },
    {
        idDistrito: "021210",
        idProvincia: "0212",
        idDepartamento: "02",
        nombre: "YURACMARCA"
    },
    {
        idDistrito: "021301",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "PISCOBAMBA"
    },
    {
        idDistrito: "021302",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "CASCA"
    },
    {
        idDistrito: "021303",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "ELEAZAR GUZMÁN BARRON"
    },
    {
        idDistrito: "021304",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "FIDEL OLIVAS ESCUDERO"
    },
    {
        idDistrito: "021305",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "LLAMA"
    },
    {
        idDistrito: "021306",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "LLUMPA"
    },
    {
        idDistrito: "021307",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "LUCMA"
    },
    {
        idDistrito: "021308",
        idProvincia: "0213",
        idDepartamento: "02",
        nombre: "MUSGA"
    },
    {
        idDistrito: "021401",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "OCROS"
    },
    {
        idDistrito: "021402",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "ACAS"
    },
    {
        idDistrito: "021403",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "CAJAMARQUILLA"
    },
    {
        idDistrito: "021404",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "CARHUAPAMPA"
    },
    {
        idDistrito: "021405",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "COCHAS"
    },
    {
        idDistrito: "021406",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "CONGAS"
    },
    {
        idDistrito: "021407",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "LLIPA"
    },
    {
        idDistrito: "021408",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "SAN CRISTÓBAL DE RAJAN"
    },
    {
        idDistrito: "021409",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "SAN PEDRO"
    },
    {
        idDistrito: "021410",
        idProvincia: "0214",
        idDepartamento: "02",
        nombre: "SANTIAGO DE CHILCAS"
    },
    {
        idDistrito: "021501",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "CABANA"
    },
    {
        idDistrito: "021502",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "BOLOGNESI"
    },
    {
        idDistrito: "021503",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "CONCHUCOS"
    },
    {
        idDistrito: "021504",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "HUACASCHUQUE"
    },
    {
        idDistrito: "021505",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "HUANDOVAL"
    },
    {
        idDistrito: "021506",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "LACABAMBA"
    },
    {
        idDistrito: "021507",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "LLAPO"
    },
    {
        idDistrito: "021508",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "PALLASCA"
    },
    {
        idDistrito: "021509",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "PAMPAS"
    },
    {
        idDistrito: "021510",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "021511",
        idProvincia: "0215",
        idDepartamento: "02",
        nombre: "TAUCA"
    },
    {
        idDistrito: "021601",
        idProvincia: "0216",
        idDepartamento: "02",
        nombre: "POMABAMBA"
    },
    {
        idDistrito: "021602",
        idProvincia: "0216",
        idDepartamento: "02",
        nombre: "HUAYLLAN"
    },
    {
        idDistrito: "021603",
        idProvincia: "0216",
        idDepartamento: "02",
        nombre: "PAROBAMBA"
    },
    {
        idDistrito: "021604",
        idProvincia: "0216",
        idDepartamento: "02",
        nombre: "QUINUABAMBA"
    },
    {
        idDistrito: "021701",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "RECUAY"
    },
    {
        idDistrito: "021702",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "CATAC"
    },
    {
        idDistrito: "021703",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "COTAPARACO"
    },
    {
        idDistrito: "021704",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "HUAYLLAPAMPA"
    },
    {
        idDistrito: "021705",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "LLACLLIN"
    },
    {
        idDistrito: "021706",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "MARCA"
    },
    {
        idDistrito: "021707",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "PAMPAS CHICO"
    },
    {
        idDistrito: "021708",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "PARARIN"
    },
    {
        idDistrito: "021709",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "TAPACOCHA"
    },
    {
        idDistrito: "021710",
        idProvincia: "0217",
        idDepartamento: "02",
        nombre: "TICAPAMPA"
    },
    {
        idDistrito: "021801",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "CHIMBOTE"
    },
    {
        idDistrito: "021802",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "CÁCERES DEL PERÚ"
    },
    {
        idDistrito: "021803",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "COISHCO"
    },
    {
        idDistrito: "021804",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "MACATE"
    },
    {
        idDistrito: "021805",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "MORO"
    },
    {
        idDistrito: "021806",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "NEPEÑA"
    },
    {
        idDistrito: "021807",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "SAMANCO"
    },
    {
        idDistrito: "021808",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "SANTA"
    },
    {
        idDistrito: "021809",
        idProvincia: "0218",
        idDepartamento: "02",
        nombre: "NUEVO CHIMBOTE"
    },
    {
        idDistrito: "021901",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "SIHUAS"
    },
    {
        idDistrito: "021902",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "ACOBAMBA"
    },
    {
        idDistrito: "021903",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "ALFONSO UGARTE"
    },
    {
        idDistrito: "021904",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "CASHAPAMPA"
    },
    {
        idDistrito: "021905",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "CHINGALPO"
    },
    {
        idDistrito: "021906",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "HUAYLLABAMBA"
    },
    {
        idDistrito: "021907",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "QUICHES"
    },
    {
        idDistrito: "021908",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "RAGASH"
    },
    {
        idDistrito: "021909",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "SAN JUAN"
    },
    {
        idDistrito: "021910",
        idProvincia: "0219",
        idDepartamento: "02",
        nombre: "SICSIBAMBA"
    },
    {
        idDistrito: "022001",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "YUNGAY"
    },
    {
        idDistrito: "022002",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "CASCAPARA"
    },
    {
        idDistrito: "022003",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "MANCOS"
    },
    {
        idDistrito: "022004",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "MATACOTO"
    },
    {
        idDistrito: "022005",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "QUILLO"
    },
    {
        idDistrito: "022006",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "RANRAHIRCA"
    },
    {
        idDistrito: "022007",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "SHUPLUY"
    },
    {
        idDistrito: "022008",
        idProvincia: "0220",
        idDepartamento: "02",
        nombre: "YANAMA"
    },
    {
        idDistrito: "030101",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "ABANCAY"
    },
    {
        idDistrito: "030102",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "CHACOCHE"
    },
    {
        idDistrito: "030103",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "CIRCA"
    },
    {
        idDistrito: "030104",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "CURAHUASI"
    },
    {
        idDistrito: "030105",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "HUANIPACA"
    },
    {
        idDistrito: "030106",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "LAMBRAMA"
    },
    {
        idDistrito: "030107",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "PICHIRHUA"
    },
    {
        idDistrito: "030108",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "SAN PEDRO DE CACHORA"
    },
    {
        idDistrito: "030109",
        idProvincia: "0301",
        idDepartamento: "03",
        nombre: "TAMBURCO"
    },
    {
        idDistrito: "030201",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "ANDAHUAYLAS"
    },
    {
        idDistrito: "030202",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "ANDARAPA"
    },
    {
        idDistrito: "030203",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "CHIARA"
    },
    {
        idDistrito: "030204",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "HUANCARAMA"
    },
    {
        idDistrito: "030205",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "HUANCARAY"
    },
    {
        idDistrito: "030206",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "HUAYANA"
    },
    {
        idDistrito: "030207",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "KISHUARA"
    },
    {
        idDistrito: "030208",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "PACOBAMBA"
    },
    {
        idDistrito: "030209",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "PACUCHA"
    },
    {
        idDistrito: "030210",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "PAMPACHIRI"
    },
    {
        idDistrito: "030211",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "POMACOCHA"
    },
    {
        idDistrito: "030212",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "SAN ANTONIO DE CACHI"
    },
    {
        idDistrito: "030213",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "SAN JERÓNIMO"
    },
    {
        idDistrito: "030214",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "SAN MIGUEL DE CHACCRAMPA"
    },
    {
        idDistrito: "030215",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "SANTA MARÍA DE CHICMO"
    },
    {
        idDistrito: "030216",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "TALAVERA"
    },
    {
        idDistrito: "030217",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "TUMAY HUARACA"
    },
    {
        idDistrito: "030218",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "TURPO"
    },
    {
        idDistrito: "030219",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "KAQUIABAMBA"
    },
    {
        idDistrito: "030220",
        idProvincia: "0302",
        idDepartamento: "03",
        nombre: "JOSÉ MARÍA ARGUEDAS"
    },
    {
        idDistrito: "030301",
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "ANTABAMBA"
    },
    {
        idDistrito: "030302",
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "EL ORO"
    },
    {
        idDistrito: "030303",
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "HUAQUIRCA"
    },
    {
        idDistrito: "030304",
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "JUAN ESPINOZA MEDRANO"
    },
    {
        idDistrito: "030305",
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "OROPESA"
    },
    {
        idDistrito: "030306",
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "PACHACONAS"
    },
    {
        idDistrito: "030307",
        idProvincia: "0303",
        idDepartamento: "03",
        nombre: "SABAINO"
    },
    {
        idDistrito: "030401",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "CHALHUANCA"
    },
    {
        idDistrito: "030402",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "CAPAYA"
    },
    {
        idDistrito: "030403",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "CARAYBAMBA"
    },
    {
        idDistrito: "030404",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "CHAPIMARCA"
    },
    {
        idDistrito: "030405",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "COLCABAMBA"
    },
    {
        idDistrito: "030406",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "COTARUSE"
    },
    {
        idDistrito: "030407",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "IHUAYLLO"
    },
    {
        idDistrito: "030408",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "JUSTO APU SAHUARAURA"
    },
    {
        idDistrito: "030409",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "LUCRE"
    },
    {
        idDistrito: "030410",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "POCOHUANCA"
    },
    {
        idDistrito: "030411",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "SAN JUAN DE CHACÑA"
    },
    {
        idDistrito: "030412",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "SAÑAYCA"
    },
    {
        idDistrito: "030413",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "SORAYA"
    },
    {
        idDistrito: "030414",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "TAPAIRIHUA"
    },
    {
        idDistrito: "030415",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "TINTAY"
    },
    {
        idDistrito: "030416",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "TORAYA"
    },
    {
        idDistrito: "030417",
        idProvincia: "0304",
        idDepartamento: "03",
        nombre: "YANACA"
    },
    {
        idDistrito: "030501",
        idProvincia: "0305",
        idDepartamento: "03",
        nombre: "TAMBOBAMBA"
    },
    {
        idDistrito: "030502",
        idProvincia: "0305",
        idDepartamento: "03",
        nombre: "COTABAMBAS"
    },
    {
        idDistrito: "030503",
        idProvincia: "0305",
        idDepartamento: "03",
        nombre: "COYLLURQUI"
    },
    {
        idDistrito: "030504",
        idProvincia: "0305",
        idDepartamento: "03",
        nombre: "HAQUIRA"
    },
    {
        idDistrito: "030505",
        idProvincia: "0305",
        idDepartamento: "03",
        nombre: "MARA"
    },
    {
        idDistrito: "030506",
        idProvincia: "0305",
        idDepartamento: "03",
        nombre: "CHALLHUAHUACHO"
    },
    {
        idDistrito: "030601",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "CHINCHEROS"
    },
    {
        idDistrito: "030602",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "ANCO_HUALLO"
    },
    {
        idDistrito: "030603",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "COCHARCAS"
    },
    {
        idDistrito: "030604",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "HUACCANA"
    },
    {
        idDistrito: "030605",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "OCOBAMBA"
    },
    {
        idDistrito: "030606",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "ONGOY"
    },
    {
        idDistrito: "030607",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "URANMARCA"
    },
    {
        idDistrito: "030608",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "RANRACANCHA"
    },
    {
        idDistrito: "030609",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "ROCCHACC"
    },
    {
        idDistrito: "030610",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "EL PORVENIR"
    },
    {
        idDistrito: "030611",
        idProvincia: "0306",
        idDepartamento: "03",
        nombre: "LOS CHANKAS"
    },
    {
        idDistrito: "030701",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "CHUQUIBAMBILLA"
    },
    {
        idDistrito: "030702",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "CURPAHUASI"
    },
    {
        idDistrito: "030703",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "GAMARRA"
    },
    {
        idDistrito: "030704",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "HUAYLLATI"
    },
    {
        idDistrito: "030705",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "MAMARA"
    },
    {
        idDistrito: "030706",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "MICAELA BASTIDAS"
    },
    {
        idDistrito: "030707",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "PATAYPAMPA"
    },
    {
        idDistrito: "030708",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "PROGRESO"
    },
    {
        idDistrito: "030709",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "SAN ANTONIO"
    },
    {
        idDistrito: "030710",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "030711",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "TURPAY"
    },
    {
        idDistrito: "030712",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "VILCABAMBA"
    },
    {
        idDistrito: "030713",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "VIRUNDO"
    },
    {
        idDistrito: "030714",
        idProvincia: "0307",
        idDepartamento: "03",
        nombre: "CURASCO"
    },
    {
        idDistrito: "040101",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "AREQUIPA"
    },
    {
        idDistrito: "040102",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "ALTO SELVA ALEGRE"
    },
    {
        idDistrito: "040103",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "CAYMA"
    },
    {
        idDistrito: "040104",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "CERRO COLORADO"
    },
    {
        idDistrito: "040105",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "CHARACATO"
    },
    {
        idDistrito: "040106",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "CHIGUATA"
    },
    {
        idDistrito: "040107",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "JACOBO HUNTER"
    },
    {
        idDistrito: "040108",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "LA JOYA"
    },
    {
        idDistrito: "040109",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "MARIANO MELGAR"
    },
    {
        idDistrito: "040110",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "MIRAFLORES"
    },
    {
        idDistrito: "040111",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "MOLLEBAYA"
    },
    {
        idDistrito: "040112",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "PAUCARPATA"
    },
    {
        idDistrito: "040113",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "POCSI"
    },
    {
        idDistrito: "040114",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "POLOBAYA"
    },
    {
        idDistrito: "040115",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "QUEQUEÑA"
    },
    {
        idDistrito: "040116",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "SABANDIA"
    },
    {
        idDistrito: "040117",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "SACHACA"
    },
    {
        idDistrito: "040118",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "SAN JUAN DE SIGUAS"
    },
    {
        idDistrito: "040119",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "SAN JUAN DE TARUCANI"
    },
    {
        idDistrito: "040120",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "SANTA ISABEL DE SIGUAS"
    },
    {
        idDistrito: "040121",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "SANTA RITA DE SIGUAS"
    },
    {
        idDistrito: "040122",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "SOCABAYA"
    },
    {
        idDistrito: "040123",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "TIABAYA"
    },
    {
        idDistrito: "040124",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "UCHUMAYO"
    },
    {
        idDistrito: "040125",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "VITOR"
    },
    {
        idDistrito: "040126",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "YANAHUARA"
    },
    {
        idDistrito: "040127",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "YARABAMBA"
    },
    {
        idDistrito: "040128",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "YURA"
    },
    {
        idDistrito: "040129",
        idProvincia: "0401",
        idDepartamento: "04",
        nombre: "JOSÉ LUIS BUSTAMANTE Y RIVERO"
    },
    {
        idDistrito: "040201",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "CAMANÁ"
    },
    {
        idDistrito: "040202",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "JOSÉ MARÍA QUIMPER"
    },
    {
        idDistrito: "040203",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "MARIANO NICOLÁS VALCÁRCEL"
    },
    {
        idDistrito: "040204",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "MARISCAL CÁCERES"
    },
    {
        idDistrito: "040205",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "NICOLÁS DE PIEROLA"
    },
    {
        idDistrito: "040206",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "OCOÑA"
    },
    {
        idDistrito: "040207",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "QUILCA"
    },
    {
        idDistrito: "040208",
        idProvincia: "0402",
        idDepartamento: "04",
        nombre: "SAMUEL PASTOR"
    },
    {
        idDistrito: "040301",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "CARAVELÍ"
    },
    {
        idDistrito: "040302",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "ACARÍ"
    },
    {
        idDistrito: "040303",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "ATICO"
    },
    {
        idDistrito: "040304",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "ATIQUIPA"
    },
    {
        idDistrito: "040305",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "BELLA UNIÓN"
    },
    {
        idDistrito: "040306",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "CAHUACHO"
    },
    {
        idDistrito: "040307",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "CHALA"
    },
    {
        idDistrito: "040308",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "CHAPARRA"
    },
    {
        idDistrito: "040309",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "HUANUHUANU"
    },
    {
        idDistrito: "040310",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "JAQUI"
    },
    {
        idDistrito: "040311",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "LOMAS"
    },
    {
        idDistrito: "040312",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "QUICACHA"
    },
    {
        idDistrito: "040313",
        idProvincia: "0403",
        idDepartamento: "04",
        nombre: "YAUCA"
    },
    {
        idDistrito: "040401",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "APLAO"
    },
    {
        idDistrito: "040402",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "ANDAGUA"
    },
    {
        idDistrito: "040403",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "AYO"
    },
    {
        idDistrito: "040404",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "CHACHAS"
    },
    {
        idDistrito: "040405",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "CHILCAYMARCA"
    },
    {
        idDistrito: "040406",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "CHOCO"
    },
    {
        idDistrito: "040407",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "HUANCARQUI"
    },
    {
        idDistrito: "040408",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "MACHAGUAY"
    },
    {
        idDistrito: "040409",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "ORCOPAMPA"
    },
    {
        idDistrito: "040410",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "PAMPACOLCA"
    },
    {
        idDistrito: "040411",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "TIPAN"
    },
    {
        idDistrito: "040412",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "UÑON"
    },
    {
        idDistrito: "040413",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "URACA"
    },
    {
        idDistrito: "040414",
        idProvincia: "0404",
        idDepartamento: "04",
        nombre: "VIRACO"
    },
    {
        idDistrito: "040501",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "CHIVAY"
    },
    {
        idDistrito: "040502",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "ACHOMA"
    },
    {
        idDistrito: "040503",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "CABANACONDE"
    },
    {
        idDistrito: "040504",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "CALLALLI"
    },
    {
        idDistrito: "040505",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "CAYLLOMA"
    },
    {
        idDistrito: "040506",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "COPORAQUE"
    },
    {
        idDistrito: "040507",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "HUAMBO"
    },
    {
        idDistrito: "040508",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "HUANCA"
    },
    {
        idDistrito: "040509",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "ICHUPAMPA"
    },
    {
        idDistrito: "040510",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "LARI"
    },
    {
        idDistrito: "040511",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "LLUTA"
    },
    {
        idDistrito: "040512",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "MACA"
    },
    {
        idDistrito: "040513",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "MADRIGAL"
    },
    {
        idDistrito: "040514",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "SAN ANTONIO DE CHUCA"
    },
    {
        idDistrito: "040515",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "SIBAYO"
    },
    {
        idDistrito: "040516",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "TAPAY"
    },
    {
        idDistrito: "040517",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "TISCO"
    },
    {
        idDistrito: "040518",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "TUTI"
    },
    {
        idDistrito: "040519",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "YANQUE"
    },
    {
        idDistrito: "040520",
        idProvincia: "0405",
        idDepartamento: "04",
        nombre: "MAJES"
    },
    {
        idDistrito: "040601",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "CHUQUIBAMBA"
    },
    {
        idDistrito: "040602",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "ANDARAY"
    },
    {
        idDistrito: "040603",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "CAYARANI"
    },
    {
        idDistrito: "040604",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "CHICHAS"
    },
    {
        idDistrito: "040605",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "IRAY"
    },
    {
        idDistrito: "040606",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "RÍO GRANDE"
    },
    {
        idDistrito: "040607",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "SALAMANCA"
    },
    {
        idDistrito: "040608",
        idProvincia: "0406",
        idDepartamento: "04",
        nombre: "YANAQUIHUA"
    },
    {
        idDistrito: "040701",
        idProvincia: "0407",
        idDepartamento: "04",
        nombre: "MOLLENDO"
    },
    {
        idDistrito: "040702",
        idProvincia: "0407",
        idDepartamento: "04",
        nombre: "COCACHACRA"
    },
    {
        idDistrito: "040703",
        idProvincia: "0407",
        idDepartamento: "04",
        nombre: "DEAN VALDIVIA"
    },
    {
        idDistrito: "040704",
        idProvincia: "0407",
        idDepartamento: "04",
        nombre: "ISLAY"
    },
    {
        idDistrito: "040705",
        idProvincia: "0407",
        idDepartamento: "04",
        nombre: "MEJIA"
    },
    {
        idDistrito: "040706",
        idProvincia: "0407",
        idDepartamento: "04",
        nombre: "PUNTA DE BOMBÓN"
    },
    {
        idDistrito: "040801",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "COTAHUASI"
    },
    {
        idDistrito: "040802",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "ALCA"
    },
    {
        idDistrito: "040803",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "CHARCANA"
    },
    {
        idDistrito: "040804",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "HUAYNACOTAS"
    },
    {
        idDistrito: "040805",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "PAMPAMARCA"
    },
    {
        idDistrito: "040806",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "PUYCA"
    },
    {
        idDistrito: "040807",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "QUECHUALLA"
    },
    {
        idDistrito: "040808",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "SAYLA"
    },
    {
        idDistrito: "040809",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "TAURIA"
    },
    {
        idDistrito: "040810",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "TOMEPAMPA"
    },
    {
        idDistrito: "040811",
        idProvincia: "0408",
        idDepartamento: "04",
        nombre: "TORO"
    },
    {
        idDistrito: "050101",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "AYACUCHO"
    },
    {
        idDistrito: "050102",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "ACOCRO"
    },
    {
        idDistrito: "050103",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "ACOS VINCHOS"
    },
    {
        idDistrito: "050104",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "CARMEN ALTO"
    },
    {
        idDistrito: "050105",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "CHIARA"
    },
    {
        idDistrito: "050106",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "OCROS"
    },
    {
        idDistrito: "050107",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "PACAYCASA"
    },
    {
        idDistrito: "050108",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "QUINUA"
    },
    {
        idDistrito: "050109",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "SAN JOSÉ DE TICLLAS"
    },
    {
        idDistrito: "050110",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "SAN JUAN BAUTISTA"
    },
    {
        idDistrito: "050111",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "SANTIAGO DE PISCHA"
    },
    {
        idDistrito: "050112",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "SOCOS"
    },
    {
        idDistrito: "050113",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "TAMBILLO"
    },
    {
        idDistrito: "050114",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "VINCHOS"
    },
    {
        idDistrito: "050115",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "JESÚS NAZARENO"
    },
    {
        idDistrito: "050116",
        idProvincia: "0501",
        idDepartamento: "05",
        nombre: "ANDRÉS AVELINO CÁCERES DORREGARAY"
    },
    {
        idDistrito: "050201",
        idProvincia: "0502",
        idDepartamento: "05",
        nombre: "CANGALLO"
    },
    {
        idDistrito: "050202",
        idProvincia: "0502",
        idDepartamento: "05",
        nombre: "CHUSCHI"
    },
    {
        idDistrito: "050203",
        idProvincia: "0502",
        idDepartamento: "05",
        nombre: "LOS MOROCHUCOS"
    },
    {
        idDistrito: "050204",
        idProvincia: "0502",
        idDepartamento: "05",
        nombre: "MARÍA PARADO DE BELLIDO"
    },
    {
        idDistrito: "050205",
        idProvincia: "0502",
        idDepartamento: "05",
        nombre: "PARAS"
    },
    {
        idDistrito: "050206",
        idProvincia: "0502",
        idDepartamento: "05",
        nombre: "TOTOS"
    },
    {
        idDistrito: "050301",
        idProvincia: "0503",
        idDepartamento: "05",
        nombre: "SANCOS"
    },
    {
        idDistrito: "050302",
        idProvincia: "0503",
        idDepartamento: "05",
        nombre: "CARAPO"
    },
    {
        idDistrito: "050303",
        idProvincia: "0503",
        idDepartamento: "05",
        nombre: "SACSAMARCA"
    },
    {
        idDistrito: "050304",
        idProvincia: "0503",
        idDepartamento: "05",
        nombre: "SANTIAGO DE LUCANAMARCA"
    },
    {
        idDistrito: "050401",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "HUANTA"
    },
    {
        idDistrito: "050402",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "AYAHUANCO"
    },
    {
        idDistrito: "050403",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "HUAMANGUILLA"
    },
    {
        idDistrito: "050404",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "IGUAIN"
    },
    {
        idDistrito: "050405",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "LURICOCHA"
    },
    {
        idDistrito: "050406",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "SANTILLANA"
    },
    {
        idDistrito: "050407",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "SIVIA"
    },
    {
        idDistrito: "050408",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "LLOCHEGUA"
    },
    {
        idDistrito: "050409",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "CANAYRE"
    },
    {
        idDistrito: "050410",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "UCHURACCAY"
    },
    {
        idDistrito: "050411",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "PUCACOLPA"
    },
    {
        idDistrito: "050412",
        idProvincia: "0504",
        idDepartamento: "05",
        nombre: "CHACA"
    },
    {
        idDistrito: "050501",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "SAN MIGUEL"
    },
    {
        idDistrito: "050502",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "ANCO"
    },
    {
        idDistrito: "050503",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "AYNA"
    },
    {
        idDistrito: "050504",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "CHILCAS"
    },
    {
        idDistrito: "050505",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "CHUNGUI"
    },
    {
        idDistrito: "050506",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "LUIS CARRANZA"
    },
    {
        idDistrito: "050507",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "050508",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "TAMBO"
    },
    {
        idDistrito: "050509",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "SAMUGARI"
    },
    {
        idDistrito: "050510",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "ANCHIHUAY"
    },
    {
        idDistrito: "050511",
        idProvincia: "0505",
        idDepartamento: "05",
        nombre: "ORONCCOY"
    },
    {
        idDistrito: "050601",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "PUQUIO"
    },
    {
        idDistrito: "050602",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "AUCARA"
    },
    {
        idDistrito: "050603",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "CABANA"
    },
    {
        idDistrito: "050604",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "CARMEN SALCEDO"
    },
    {
        idDistrito: "050605",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "CHAVIÑA"
    },
    {
        idDistrito: "050606",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "CHIPAO"
    },
    {
        idDistrito: "050607",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "HUAC-HUAS"
    },
    {
        idDistrito: "050608",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "LARAMATE"
    },
    {
        idDistrito: "050609",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "LEONCIO PRADO"
    },
    {
        idDistrito: "050610",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "LLAUTA"
    },
    {
        idDistrito: "050611",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "LUCANAS"
    },
    {
        idDistrito: "050612",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "OCAÑA"
    },
    {
        idDistrito: "050613",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "OTOCA"
    },
    {
        idDistrito: "050614",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SAISA"
    },
    {
        idDistrito: "050615",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SAN CRISTÓBAL"
    },
    {
        idDistrito: "050616",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SAN JUAN"
    },
    {
        idDistrito: "050617",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SAN PEDRO"
    },
    {
        idDistrito: "050618",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SAN PEDRO DE PALCO"
    },
    {
        idDistrito: "050619",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SANCOS"
    },
    {
        idDistrito: "050620",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SANTA ANA DE HUAYCAHUACHO"
    },
    {
        idDistrito: "050621",
        idProvincia: "0506",
        idDepartamento: "05",
        nombre: "SANTA LUCIA"
    },
    {
        idDistrito: "050701",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "CORACORA"
    },
    {
        idDistrito: "050702",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "CHUMPI"
    },
    {
        idDistrito: "050703",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "CORONEL CASTAÑEDA"
    },
    {
        idDistrito: "050704",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "PACAPAUSA"
    },
    {
        idDistrito: "050705",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "PULLO"
    },
    {
        idDistrito: "050706",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "PUYUSCA"
    },
    {
        idDistrito: "050707",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "SAN FRANCISCO DE RAVACAYCO"
    },
    {
        idDistrito: "050708",
        idProvincia: "0507",
        idDepartamento: "05",
        nombre: "UPAHUACHO"
    },
    {
        idDistrito: "050801",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "PAUSA"
    },
    {
        idDistrito: "050802",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "COLTA"
    },
    {
        idDistrito: "050803",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "CORCULLA"
    },
    {
        idDistrito: "050804",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "LAMPA"
    },
    {
        idDistrito: "050805",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "MARCABAMBA"
    },
    {
        idDistrito: "050806",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "OYOLO"
    },
    {
        idDistrito: "050807",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "PARARCA"
    },
    {
        idDistrito: "050808",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "SAN JAVIER DE ALPABAMBA"
    },
    {
        idDistrito: "050809",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "SAN JOSÉ DE USHUA"
    },
    {
        idDistrito: "050810",
        idProvincia: "0508",
        idDepartamento: "05",
        nombre: "SARA SARA"
    },
    {
        idDistrito: "050901",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "QUEROBAMBA"
    },
    {
        idDistrito: "050902",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "BELÉN"
    },
    {
        idDistrito: "050903",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "CHALCOS"
    },
    {
        idDistrito: "050904",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "CHILCAYOC"
    },
    {
        idDistrito: "050905",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "HUACAÑA"
    },
    {
        idDistrito: "050906",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "MORCOLLA"
    },
    {
        idDistrito: "050907",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "PAICO"
    },
    {
        idDistrito: "050908",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "SAN PEDRO DE LARCAY"
    },
    {
        idDistrito: "050909",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "SAN SALVADOR DE QUIJE"
    },
    {
        idDistrito: "050910",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "SANTIAGO DE PAUCARAY"
    },
    {
        idDistrito: "050911",
        idProvincia: "0509",
        idDepartamento: "05",
        nombre: "SORAS"
    },
    {
        idDistrito: "051001",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "HUANCAPI"
    },
    {
        idDistrito: "051002",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "ALCAMENCA"
    },
    {
        idDistrito: "051003",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "APONGO"
    },
    {
        idDistrito: "051004",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "ASQUIPATA"
    },
    {
        idDistrito: "051005",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "CANARIA"
    },
    {
        idDistrito: "051006",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "CAYARA"
    },
    {
        idDistrito: "051007",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "COLCA"
    },
    {
        idDistrito: "051008",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "HUAMANQUIQUIA"
    },
    {
        idDistrito: "051009",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "HUANCARAYLLA"
    },
    {
        idDistrito: "051010",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "HUALLA"
    },
    {
        idDistrito: "051011",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "SARHUA"
    },
    {
        idDistrito: "051012",
        idProvincia: "0510",
        idDepartamento: "05",
        nombre: "VILCANCHOS"
    },
    {
        idDistrito: "051101",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "VILCAS HUAMAN"
    },
    {
        idDistrito: "051102",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "ACCOMARCA"
    },
    {
        idDistrito: "051103",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "CARHUANCA"
    },
    {
        idDistrito: "051104",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "CONCEPCIÓN"
    },
    {
        idDistrito: "051105",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "HUAMBALPA"
    },
    {
        idDistrito: "051106",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "INDEPENDENCIA"
    },
    {
        idDistrito: "051107",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "SAURAMA"
    },
    {
        idDistrito: "051108",
        idProvincia: "0511",
        idDepartamento: "05",
        nombre: "VISCHONGO"
    },
    {
        idDistrito: "060101",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "CAJAMARCA"
    },
    {
        idDistrito: "060102",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "ASUNCIÓN"
    },
    {
        idDistrito: "060103",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "CHETILLA"
    },
    {
        idDistrito: "060104",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "COSPAN"
    },
    {
        idDistrito: "060105",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "ENCAÑADA"
    },
    {
        idDistrito: "060106",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "JESÚS"
    },
    {
        idDistrito: "060107",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "LLACANORA"
    },
    {
        idDistrito: "060108",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "LOS BAÑOS DEL INCA"
    },
    {
        idDistrito: "060109",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "MAGDALENA"
    },
    {
        idDistrito: "060110",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "MATARA"
    },
    {
        idDistrito: "060111",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "NAMORA"
    },
    {
        idDistrito: "060112",
        idProvincia: "0601",
        idDepartamento: "06",
        nombre: "SAN JUAN"
    },
    {
        idDistrito: "060201",
        idProvincia: "0602",
        idDepartamento: "06",
        nombre: "CAJABAMBA"
    },
    {
        idDistrito: "060202",
        idProvincia: "0602",
        idDepartamento: "06",
        nombre: "CACHACHI"
    },
    {
        idDistrito: "060203",
        idProvincia: "0602",
        idDepartamento: "06",
        nombre: "CONDEBAMBA"
    },
    {
        idDistrito: "060204",
        idProvincia: "0602",
        idDepartamento: "06",
        nombre: "SITACOCHA"
    },
    {
        idDistrito: "060301",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "CELENDÍN"
    },
    {
        idDistrito: "060302",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "CHUMUCH"
    },
    {
        idDistrito: "060303",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "CORTEGANA"
    },
    {
        idDistrito: "060304",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "HUASMIN"
    },
    {
        idDistrito: "060305",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "JORGE CHÁVEZ"
    },
    {
        idDistrito: "060306",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "JOSÉ GÁLVEZ"
    },
    {
        idDistrito: "060307",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "MIGUEL IGLESIAS"
    },
    {
        idDistrito: "060308",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "OXAMARCA"
    },
    {
        idDistrito: "060309",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "SOROCHUCO"
    },
    {
        idDistrito: "060310",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "SUCRE"
    },
    {
        idDistrito: "060311",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "UTCO"
    },
    {
        idDistrito: "060312",
        idProvincia: "0603",
        idDepartamento: "06",
        nombre: "LA LIBERTAD DE PALLAN"
    },
    {
        idDistrito: "060401",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CHOTA"
    },
    {
        idDistrito: "060402",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "ANGUIA"
    },
    {
        idDistrito: "060403",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CHADIN"
    },
    {
        idDistrito: "060404",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CHIGUIRIP"
    },
    {
        idDistrito: "060405",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CHIMBAN"
    },
    {
        idDistrito: "060406",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CHOROPAMPA"
    },
    {
        idDistrito: "060407",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "COCHABAMBA"
    },
    {
        idDistrito: "060408",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CONCHAN"
    },
    {
        idDistrito: "060409",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "HUAMBOS"
    },
    {
        idDistrito: "060410",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "LAJAS"
    },
    {
        idDistrito: "060411",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "LLAMA"
    },
    {
        idDistrito: "060412",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "MIRACOSTA"
    },
    {
        idDistrito: "060413",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "PACCHA"
    },
    {
        idDistrito: "060414",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "PION"
    },
    {
        idDistrito: "060415",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "QUEROCOTO"
    },
    {
        idDistrito: "060416",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "SAN JUAN DE LICUPIS"
    },
    {
        idDistrito: "060417",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "TACABAMBA"
    },
    {
        idDistrito: "060418",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "TOCMOCHE"
    },
    {
        idDistrito: "060419",
        idProvincia: "0604",
        idDepartamento: "06",
        nombre: "CHALAMARCA"
    },
    {
        idDistrito: "060501",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "CONTUMAZA"
    },
    {
        idDistrito: "060502",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "CHILETE"
    },
    {
        idDistrito: "060503",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "CUPISNIQUE"
    },
    {
        idDistrito: "060504",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "GUZMANGO"
    },
    {
        idDistrito: "060505",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "SAN BENITO"
    },
    {
        idDistrito: "060506",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "SANTA CRUZ DE TOLEDO"
    },
    {
        idDistrito: "060507",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "TANTARICA"
    },
    {
        idDistrito: "060508",
        idProvincia: "0605",
        idDepartamento: "06",
        nombre: "YONAN"
    },
    {
        idDistrito: "060601",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "CUTERVO"
    },
    {
        idDistrito: "060602",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "CALLAYUC"
    },
    {
        idDistrito: "060603",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "CHOROS"
    },
    {
        idDistrito: "060604",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "CUJILLO"
    },
    {
        idDistrito: "060605",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "LA RAMADA"
    },
    {
        idDistrito: "060606",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "PIMPINGOS"
    },
    {
        idDistrito: "060607",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "QUEROCOTILLO"
    },
    {
        idDistrito: "060608",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "SAN ANDRÉS DE CUTERVO"
    },
    {
        idDistrito: "060609",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "SAN JUAN DE CUTERVO"
    },
    {
        idDistrito: "060610",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "SAN LUIS DE LUCMA"
    },
    {
        idDistrito: "060611",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "SANTA CRUZ"
    },
    {
        idDistrito: "060612",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "SANTO DOMINGO DE LA CAPILLA"
    },
    {
        idDistrito: "060613",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "SANTO TOMAS"
    },
    {
        idDistrito: "060614",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "SOCOTA"
    },
    {
        idDistrito: "060615",
        idProvincia: "0606",
        idDepartamento: "06",
        nombre: "TORIBIO CASANOVA"
    },
    {
        idDistrito: "060701",
        idProvincia: "0607",
        idDepartamento: "06",
        nombre: "BAMBAMARCA"
    },
    {
        idDistrito: "060702",
        idProvincia: "0607",
        idDepartamento: "06",
        nombre: "CHUGUR"
    },
    {
        idDistrito: "060703",
        idProvincia: "0607",
        idDepartamento: "06",
        nombre: "HUALGAYOC"
    },
    {
        idDistrito: "060801",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "JAÉN"
    },
    {
        idDistrito: "060802",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "BELLAVISTA"
    },
    {
        idDistrito: "060803",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "CHONTALI"
    },
    {
        idDistrito: "060804",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "COLASAY"
    },
    {
        idDistrito: "060805",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "HUABAL"
    },
    {
        idDistrito: "060806",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "LAS PIRIAS"
    },
    {
        idDistrito: "060807",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "POMAHUACA"
    },
    {
        idDistrito: "060808",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "PUCARA"
    },
    {
        idDistrito: "060809",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "SALLIQUE"
    },
    {
        idDistrito: "060810",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "SAN FELIPE"
    },
    {
        idDistrito: "060811",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "SAN JOSÉ DEL ALTO"
    },
    {
        idDistrito: "060812",
        idProvincia: "0608",
        idDepartamento: "06",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "060901",
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "SAN IGNACIO"
    },
    {
        idDistrito: "060902",
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "CHIRINOS"
    },
    {
        idDistrito: "060903",
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "HUARANGO"
    },
    {
        idDistrito: "060904",
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "LA COIPA"
    },
    {
        idDistrito: "060905",
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "NAMBALLE"
    },
    {
        idDistrito: "060906",
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "SAN JOSÉ DE LOURDES"
    },
    {
        idDistrito: "060907",
        idProvincia: "0609",
        idDepartamento: "06",
        nombre: "TABACONAS"
    },
    {
        idDistrito: "061001",
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "PEDRO GÁLVEZ"
    },
    {
        idDistrito: "061002",
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "CHANCAY"
    },
    {
        idDistrito: "061003",
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "EDUARDO VILLANUEVA"
    },
    {
        idDistrito: "061004",
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "GREGORIO PITA"
    },
    {
        idDistrito: "061005",
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "ICHOCAN"
    },
    {
        idDistrito: "061006",
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "JOSÉ MANUEL QUIROZ"
    },
    {
        idDistrito: "061007",
        idProvincia: "0610",
        idDepartamento: "06",
        nombre: "JOSÉ SABOGAL"
    },
    {
        idDistrito: "061101",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "SAN MIGUEL"
    },
    {
        idDistrito: "061102",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "BOLÍVAR"
    },
    {
        idDistrito: "061103",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "CALQUIS"
    },
    {
        idDistrito: "061104",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "CATILLUC"
    },
    {
        idDistrito: "061105",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "EL PRADO"
    },
    {
        idDistrito: "061106",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "LA FLORIDA"
    },
    {
        idDistrito: "061107",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "LLAPA"
    },
    {
        idDistrito: "061108",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "NANCHOC"
    },
    {
        idDistrito: "061109",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "NIEPOS"
    },
    {
        idDistrito: "061110",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "SAN GREGORIO"
    },
    {
        idDistrito: "061111",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "SAN SILVESTRE DE COCHAN"
    },
    {
        idDistrito: "061112",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "TONGOD"
    },
    {
        idDistrito: "061113",
        idProvincia: "0611",
        idDepartamento: "06",
        nombre: "UNIÓN AGUA BLANCA"
    },
    {
        idDistrito: "061201",
        idProvincia: "0612",
        idDepartamento: "06",
        nombre: "SAN PABLO"
    },
    {
        idDistrito: "061202",
        idProvincia: "0612",
        idDepartamento: "06",
        nombre: "SAN BERNARDINO"
    },
    {
        idDistrito: "061203",
        idProvincia: "0612",
        idDepartamento: "06",
        nombre: "SAN LUIS"
    },
    {
        idDistrito: "061204",
        idProvincia: "0612",
        idDepartamento: "06",
        nombre: "TUMBADEN"
    },
    {
        idDistrito: "061301",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "SANTA CRUZ"
    },
    {
        idDistrito: "061302",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "ANDABAMBA"
    },
    {
        idDistrito: "061303",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "CATACHE"
    },
    {
        idDistrito: "061304",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "CHANCAYBAÑOS"
    },
    {
        idDistrito: "061305",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "LA ESPERANZA"
    },
    {
        idDistrito: "061306",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "NINABAMBA"
    },
    {
        idDistrito: "061307",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "PULAN"
    },
    {
        idDistrito: "061308",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "SAUCEPAMPA"
    },
    {
        idDistrito: "061309",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "SEXI"
    },
    {
        idDistrito: "061310",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "UTICYACU"
    },
    {
        idDistrito: "061311",
        idProvincia: "0613",
        idDepartamento: "06",
        nombre: "YAUYUCAN"
    },
    {
        idDistrito: "070101",
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "CALLAO"
    },
    {
        idDistrito: "070102",
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "BELLAVISTA"
    },
    {
        idDistrito: "070103",
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "CARMEN DE LA LEGUA REYNOSO"
    },
    {
        idDistrito: "070104",
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "LA PERLA"
    },
    {
        idDistrito: "070105",
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "LA PUNTA"
    },
    {
        idDistrito: "070106",
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "VENTANILLA"
    },
    {
        idDistrito: "070107",
        idProvincia: "0701",
        idDepartamento: "07",
        nombre: "MI PERÚ"
    },
    {
        idDistrito: "080101",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "CUSCO"
    },
    {
        idDistrito: "080102",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "CCORCA"
    },
    {
        idDistrito: "080103",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "POROY"
    },
    {
        idDistrito: "080104",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "SAN JERÓNIMO"
    },
    {
        idDistrito: "080105",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "SAN SEBASTIAN"
    },
    {
        idDistrito: "080106",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "SANTIAGO"
    },
    {
        idDistrito: "080107",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "SAYLLA"
    },
    {
        idDistrito: "080108",
        idProvincia: "0801",
        idDepartamento: "08",
        nombre: "WANCHAQ"
    },
    {
        idDistrito: "080201",
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "ACOMAYO"
    },
    {
        idDistrito: "080202",
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "ACOPIA"
    },
    {
        idDistrito: "080203",
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "ACOS"
    },
    {
        idDistrito: "080204",
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "MOSOC LLACTA"
    },
    {
        idDistrito: "080205",
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "POMACANCHI"
    },
    {
        idDistrito: "080206",
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "RONDOCAN"
    },
    {
        idDistrito: "080207",
        idProvincia: "0802",
        idDepartamento: "08",
        nombre: "SANGARARA"
    },
    {
        idDistrito: "080301",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "ANTA"
    },
    {
        idDistrito: "080302",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "ANCAHUASI"
    },
    {
        idDistrito: "080303",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "CACHIMAYO"
    },
    {
        idDistrito: "080304",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "CHINCHAYPUJIO"
    },
    {
        idDistrito: "080305",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "HUAROCONDO"
    },
    {
        idDistrito: "080306",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "LIMATAMBO"
    },
    {
        idDistrito: "080307",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "MOLLEPATA"
    },
    {
        idDistrito: "080308",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "PUCYURA"
    },
    {
        idDistrito: "080309",
        idProvincia: "0803",
        idDepartamento: "08",
        nombre: "ZURITE"
    },
    {
        idDistrito: "080401",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "CALCA"
    },
    {
        idDistrito: "080402",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "COYA"
    },
    {
        idDistrito: "080403",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "LAMAY"
    },
    {
        idDistrito: "080404",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "LARES"
    },
    {
        idDistrito: "080405",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "PISAC"
    },
    {
        idDistrito: "080406",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "SAN SALVADOR"
    },
    {
        idDistrito: "080407",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "TARAY"
    },
    {
        idDistrito: "080408",
        idProvincia: "0804",
        idDepartamento: "08",
        nombre: "YANATILE"
    },
    {
        idDistrito: "080501",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "YANAOCA"
    },
    {
        idDistrito: "080502",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "CHECCA"
    },
    {
        idDistrito: "080503",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "KUNTURKANKI"
    },
    {
        idDistrito: "080504",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "LANGUI"
    },
    {
        idDistrito: "080505",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "LAYO"
    },
    {
        idDistrito: "080506",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "PAMPAMARCA"
    },
    {
        idDistrito: "080507",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "QUEHUE"
    },
    {
        idDistrito: "080508",
        idProvincia: "0805",
        idDepartamento: "08",
        nombre: "TUPAC AMARU"
    },
    {
        idDistrito: "080601",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "SICUANI"
    },
    {
        idDistrito: "080602",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "CHECACUPE"
    },
    {
        idDistrito: "080603",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "COMBAPATA"
    },
    {
        idDistrito: "080604",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "MARANGANI"
    },
    {
        idDistrito: "080605",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "PITUMARCA"
    },
    {
        idDistrito: "080606",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "SAN PABLO"
    },
    {
        idDistrito: "080607",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "SAN PEDRO"
    },
    {
        idDistrito: "080608",
        idProvincia: "0806",
        idDepartamento: "08",
        nombre: "TINTA"
    },
    {
        idDistrito: "080701",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "SANTO TOMAS"
    },
    {
        idDistrito: "080702",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "CAPACMARCA"
    },
    {
        idDistrito: "080703",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "CHAMACA"
    },
    {
        idDistrito: "080704",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "COLQUEMARCA"
    },
    {
        idDistrito: "080705",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "LIVITACA"
    },
    {
        idDistrito: "080706",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "LLUSCO"
    },
    {
        idDistrito: "080707",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "QUIÑOTA"
    },
    {
        idDistrito: "080708",
        idProvincia: "0807",
        idDepartamento: "08",
        nombre: "VELILLE"
    },
    {
        idDistrito: "080801",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "ESPINAR"
    },
    {
        idDistrito: "080802",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "CONDOROMA"
    },
    {
        idDistrito: "080803",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "COPORAQUE"
    },
    {
        idDistrito: "080804",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "OCORURO"
    },
    {
        idDistrito: "080805",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "PALLPATA"
    },
    {
        idDistrito: "080806",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "PICHIGUA"
    },
    {
        idDistrito: "080807",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "SUYCKUTAMBO"
    },
    {
        idDistrito: "080808",
        idProvincia: "0808",
        idDepartamento: "08",
        nombre: "ALTO PICHIGUA"
    },
    {
        idDistrito: "080901",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "SANTA ANA"
    },
    {
        idDistrito: "080902",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "ECHARATE"
    },
    {
        idDistrito: "080903",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "HUAYOPATA"
    },
    {
        idDistrito: "080904",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "MARANURA"
    },
    {
        idDistrito: "080905",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "OCOBAMBA"
    },
    {
        idDistrito: "080906",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "QUELLOUNO"
    },
    {
        idDistrito: "080907",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "KIMBIRI"
    },
    {
        idDistrito: "080908",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "SANTA TERESA"
    },
    {
        idDistrito: "080909",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "VILCABAMBA"
    },
    {
        idDistrito: "080910",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "PICHARI"
    },
    {
        idDistrito: "080911",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "INKAWASI"
    },
    {
        idDistrito: "080912",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "VILLA VIRGEN"
    },
    {
        idDistrito: "080913",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "VILLA KINTIARINA"
    },
    {
        idDistrito: "080914",
        idProvincia: "0809",
        idDepartamento: "08",
        nombre: "MEGANTONI"
    },
    {
        idDistrito: "081001",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "PARURO"
    },
    {
        idDistrito: "081002",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "ACCHA"
    },
    {
        idDistrito: "081003",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "CCAPI"
    },
    {
        idDistrito: "081004",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "COLCHA"
    },
    {
        idDistrito: "081005",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "HUANOQUITE"
    },
    {
        idDistrito: "081006",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "OMACHAÇ"
    },
    {
        idDistrito: "081007",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "PACCARITAMBO"
    },
    {
        idDistrito: "081008",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "PILLPINTO"
    },
    {
        idDistrito: "081009",
        idProvincia: "0810",
        idDepartamento: "08",
        nombre: "YAURISQUE"
    },
    {
        idDistrito: "081101",
        idProvincia: "0811",
        idDepartamento: "08",
        nombre: "PAUCARTAMBO"
    },
    {
        idDistrito: "081102",
        idProvincia: "0811",
        idDepartamento: "08",
        nombre: "CAICAY"
    },
    {
        idDistrito: "081103",
        idProvincia: "0811",
        idDepartamento: "08",
        nombre: "CHALLABAMBA"
    },
    {
        idDistrito: "081104",
        idProvincia: "0811",
        idDepartamento: "08",
        nombre: "COLQUEPATA"
    },
    {
        idDistrito: "081105",
        idProvincia: "0811",
        idDepartamento: "08",
        nombre: "HUANCARANI"
    },
    {
        idDistrito: "081106",
        idProvincia: "0811",
        idDepartamento: "08",
        nombre: "KOSÑIPATA"
    },
    {
        idDistrito: "081201",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "URCOS"
    },
    {
        idDistrito: "081202",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "ANDAHUAYLILLAS"
    },
    {
        idDistrito: "081203",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "CAMANTI"
    },
    {
        idDistrito: "081204",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "CCARHUAYO"
    },
    {
        idDistrito: "081205",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "CCATCA"
    },
    {
        idDistrito: "081206",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "CUSIPATA"
    },
    {
        idDistrito: "081207",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "HUARO"
    },
    {
        idDistrito: "081208",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "LUCRE"
    },
    {
        idDistrito: "081209",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "MARCAPATA"
    },
    {
        idDistrito: "081210",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "OCONGATE"
    },
    {
        idDistrito: "081211",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "OROPESA"
    },
    {
        idDistrito: "081212",
        idProvincia: "0812",
        idDepartamento: "08",
        nombre: "QUIQUIJANA"
    },
    {
        idDistrito: "081301",
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "URUBAMBA"
    },
    {
        idDistrito: "081302",
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "CHINCHERO"
    },
    {
        idDistrito: "081303",
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "HUAYLLABAMBA"
    },
    {
        idDistrito: "081304",
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "MACHUPICCHU"
    },
    {
        idDistrito: "081305",
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "MARAS"
    },
    {
        idDistrito: "081306",
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "OLLANTAYTAMBO"
    },
    {
        idDistrito: "081307",
        idProvincia: "0813",
        idDepartamento: "08",
        nombre: "YUCAY"
    },
    {
        idDistrito: "090101",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "HUANCAVELICA"
    },
    {
        idDistrito: "090102",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "ACOBAMBILLA"
    },
    {
        idDistrito: "090103",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "ACORIA"
    },
    {
        idDistrito: "090104",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "CONAYCA"
    },
    {
        idDistrito: "090105",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "CUENCA"
    },
    {
        idDistrito: "090106",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "HUACHOCOLPA"
    },
    {
        idDistrito: "090107",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "HUAYLLAHUARA"
    },
    {
        idDistrito: "090108",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "IZCUCHACA"
    },
    {
        idDistrito: "090109",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "LARIA"
    },
    {
        idDistrito: "090110",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "MANTA"
    },
    {
        idDistrito: "090111",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "MARISCAL CÁCERES"
    },
    {
        idDistrito: "090112",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "MOYA"
    },
    {
        idDistrito: "090113",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "NUEVO OCCORO"
    },
    {
        idDistrito: "090114",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "PALCA"
    },
    {
        idDistrito: "090115",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "PILCHACA"
    },
    {
        idDistrito: "090116",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "VILCA"
    },
    {
        idDistrito: "090117",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "YAULI"
    },
    {
        idDistrito: "090118",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "ASCENSIÓN"
    },
    {
        idDistrito: "090119",
        idProvincia: "0901",
        idDepartamento: "09",
        nombre: "HUANDO"
    },
    {
        idDistrito: "090201",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "ACOBAMBA"
    },
    {
        idDistrito: "090202",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "ANDABAMBA"
    },
    {
        idDistrito: "090203",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "ANTA"
    },
    {
        idDistrito: "090204",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "CAJA"
    },
    {
        idDistrito: "090205",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "MARCAS"
    },
    {
        idDistrito: "090206",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "PAUCARA"
    },
    {
        idDistrito: "090207",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "POMACOCHA"
    },
    {
        idDistrito: "090208",
        idProvincia: "0902",
        idDepartamento: "09",
        nombre: "ROSARIO"
    },
    {
        idDistrito: "090301",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "LIRCAY"
    },
    {
        idDistrito: "090302",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "ANCHONGA"
    },
    {
        idDistrito: "090303",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "CALLANMARCA"
    },
    {
        idDistrito: "090304",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "CCOCHACCASA"
    },
    {
        idDistrito: "090305",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "CHINCHO"
    },
    {
        idDistrito: "090306",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "CONGALLA"
    },
    {
        idDistrito: "090307",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "HUANCA-HUANCA"
    },
    {
        idDistrito: "090308",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "HUAYLLAY GRANDE"
    },
    {
        idDistrito: "090309",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "JULCAMARCA"
    },
    {
        idDistrito: "090310",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "SAN ANTONIO DE ANTAPARCO"
    },
    {
        idDistrito: "090311",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "SANTO TOMAS DE PATA"
    },
    {
        idDistrito: "090312",
        idProvincia: "0903",
        idDepartamento: "09",
        nombre: "SECCLLA"
    },
    {
        idDistrito: "090401",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "CASTROVIRREYNA"
    },
    {
        idDistrito: "090402",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "ARMA"
    },
    {
        idDistrito: "090403",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "AURAHUA"
    },
    {
        idDistrito: "090404",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "CAPILLAS"
    },
    {
        idDistrito: "090405",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "CHUPAMARCA"
    },
    {
        idDistrito: "090406",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "COCAS"
    },
    {
        idDistrito: "090407",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "HUACHOS"
    },
    {
        idDistrito: "090408",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "HUAMATAMBO"
    },
    {
        idDistrito: "090409",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "MOLLEPAMPA"
    },
    {
        idDistrito: "090410",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "SAN JUAN"
    },
    {
        idDistrito: "090411",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "SANTA ANA"
    },
    {
        idDistrito: "090412",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "TANTARA"
    },
    {
        idDistrito: "090413",
        idProvincia: "0904",
        idDepartamento: "09",
        nombre: "TICRAPO"
    },
    {
        idDistrito: "090501",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "CHURCAMPA"
    },
    {
        idDistrito: "090502",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "ANCO"
    },
    {
        idDistrito: "090503",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "CHINCHIHUASI"
    },
    {
        idDistrito: "090504",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "EL CARMEN"
    },
    {
        idDistrito: "090505",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "LA MERCED"
    },
    {
        idDistrito: "090506",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "LOCROJA"
    },
    {
        idDistrito: "090507",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "PAUCARBAMBA"
    },
    {
        idDistrito: "090508",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "SAN MIGUEL DE MAYOCC"
    },
    {
        idDistrito: "090509",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "SAN PEDRO DE CORIS"
    },
    {
        idDistrito: "090510",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "PACHAMARCA"
    },
    {
        idDistrito: "090511",
        idProvincia: "0905",
        idDepartamento: "09",
        nombre: "COSME"
    },
    {
        idDistrito: "090601",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "HUAYTARA"
    },
    {
        idDistrito: "090602",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "AYAVI"
    },
    {
        idDistrito: "090603",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "CÓRDOVA"
    },
    {
        idDistrito: "090604",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "HUAYACUNDO ARMA"
    },
    {
        idDistrito: "090605",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "LARAMARCA"
    },
    {
        idDistrito: "090606",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "OCOYO"
    },
    {
        idDistrito: "090607",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "PILPICHACA"
    },
    {
        idDistrito: "090608",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "QUERCO"
    },
    {
        idDistrito: "090609",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "QUITO-ARMA"
    },
    {
        idDistrito: "090610",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "SAN ANTONIO DE CUSICANCHA"
    },
    {
        idDistrito: "090611",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "SAN FRANCISCO DE SANGAYAICO"
    },
    {
        idDistrito: "090612",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "SAN ISIDRO"
    },
    {
        idDistrito: "090613",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "SANTIAGO DE CHOCORVOS"
    },
    {
        idDistrito: "090614",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "SANTIAGO DE QUIRAHUARA"
    },
    {
        idDistrito: "090615",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "SANTO DOMINGO DE CAPILLAS"
    },
    {
        idDistrito: "090616",
        idProvincia: "0906",
        idDepartamento: "09",
        nombre: "TAMBO"
    },
    {
        idDistrito: "090701",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "PAMPAS"
    },
    {
        idDistrito: "090702",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "ACOSTAMBO"
    },
    {
        idDistrito: "090703",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "ACRAQUIA"
    },
    {
        idDistrito: "090704",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "AHUAYCHA"
    },
    {
        idDistrito: "090705",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "COLCABAMBA"
    },
    {
        idDistrito: "090706",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "DANIEL HERNÁNDEZ"
    },
    {
        idDistrito: "090707",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "HUACHOCOLPA"
    },
    {
        idDistrito: "090709",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "HUARIBAMBA"
    },
    {
        idDistrito: "090710",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "ÑAHUIMPUQUIO"
    },
    {
        idDistrito: "090711",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "PAZOS"
    },
    {
        idDistrito: "090713",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "QUISHUAR"
    },
    {
        idDistrito: "090714",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "SALCABAMBA"
    },
    {
        idDistrito: "090715",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "SALCAHUASI"
    },
    {
        idDistrito: "090716",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "SAN MARCOS DE ROCCHAC"
    },
    {
        idDistrito: "090717",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "SURCUBAMBA"
    },
    {
        idDistrito: "090718",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "TINTAY PUNCU"
    },
    {
        idDistrito: "090719",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "QUICHUAS"
    },
    {
        idDistrito: "090720",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "ANDAYMARCA"
    },
    {
        idDistrito: "090721",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "ROBLE"
    },
    {
        idDistrito: "090722",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "PICHOS"
    },
    {
        idDistrito: "090723",
        idProvincia: "0907",
        idDepartamento: "09",
        nombre: "SANTIAGO DE TUCUMA"
    },
    {
        idDistrito: "100101",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "HUANUCO"
    },
    {
        idDistrito: "100102",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "AMARILIS"
    },
    {
        idDistrito: "100103",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "CHINCHAO"
    },
    {
        idDistrito: "100104",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "CHURUBAMBA"
    },
    {
        idDistrito: "100105",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "MARGOS"
    },
    {
        idDistrito: "100106",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "QUISQUI (KICHKI)"
    },
    {
        idDistrito: "100107",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "SAN FRANCISCO DE CAYRAN"
    },
    {
        idDistrito: "100108",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "SAN PEDRO DE CHAULAN"
    },
    {
        idDistrito: "100109",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "SANTA MARÍA DEL VALLE"
    },
    {
        idDistrito: "100110",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "YARUMAYO"
    },
    {
        idDistrito: "100111",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "PILLCO MARCA"
    },
    {
        idDistrito: "100112",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "YACUS"
    },
    {
        idDistrito: "100113",
        idProvincia: "1001",
        idDepartamento: "10",
        nombre: "SAN PABLO DE PILLAO"
    },
    {
        idDistrito: "100201",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "AMBO"
    },
    {
        idDistrito: "100202",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "CAYNA"
    },
    {
        idDistrito: "100203",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "COLPAS"
    },
    {
        idDistrito: "100204",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "CONCHAMARCA"
    },
    {
        idDistrito: "100205",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "HUACAR"
    },
    {
        idDistrito: "100206",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "SAN FRANCISCO"
    },
    {
        idDistrito: "100207",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "SAN RAFAEL"
    },
    {
        idDistrito: "100208",
        idProvincia: "1002",
        idDepartamento: "10",
        nombre: "TOMAY KICHWA"
    },
    {
        idDistrito: "100301",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "LA UNIÓN"
    },
    {
        idDistrito: "100307",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "CHUQUIS"
    },
    {
        idDistrito: "100311",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "MARÍAS"
    },
    {
        idDistrito: "100313",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "PACHAS"
    },
    {
        idDistrito: "100316",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "QUIVILLA"
    },
    {
        idDistrito: "100317",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "RIPAN"
    },
    {
        idDistrito: "100321",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "SHUNQUI"
    },
    {
        idDistrito: "100322",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "SILLAPATA"
    },
    {
        idDistrito: "100323",
        idProvincia: "1003",
        idDepartamento: "10",
        nombre: "YANAS"
    },
    {
        idDistrito: "100401",
        idProvincia: "1004",
        idDepartamento: "10",
        nombre: "HUACAYBAMBA"
    },
    {
        idDistrito: "100402",
        idProvincia: "1004",
        idDepartamento: "10",
        nombre: "CANCHABAMBA"
    },
    {
        idDistrito: "100403",
        idProvincia: "1004",
        idDepartamento: "10",
        nombre: "COCHABAMBA"
    },
    {
        idDistrito: "100404",
        idProvincia: "1004",
        idDepartamento: "10",
        nombre: "PINRA"
    },
    {
        idDistrito: "100501",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "LLATA"
    },
    {
        idDistrito: "100502",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "ARANCAY"
    },
    {
        idDistrito: "100503",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "CHAVÍN DE PARIARCA"
    },
    {
        idDistrito: "100504",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "JACAS GRANDE"
    },
    {
        idDistrito: "100505",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "JIRCAN"
    },
    {
        idDistrito: "100506",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "MIRAFLORES"
    },
    {
        idDistrito: "100507",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "MONZÓN"
    },
    {
        idDistrito: "100508",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "PUNCHAO"
    },
    {
        idDistrito: "100509",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "PUÑOS"
    },
    {
        idDistrito: "100510",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "SINGA"
    },
    {
        idDistrito: "100511",
        idProvincia: "1005",
        idDepartamento: "10",
        nombre: "TANTAMAYO"
    },
    {
        idDistrito: "100601",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "RUPA-RUPA"
    },
    {
        idDistrito: "100602",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "DANIEL ALOMÍA ROBLES"
    },
    {
        idDistrito: "100603",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "HERMÍLIO VALDIZAN"
    },
    {
        idDistrito: "100604",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "JOSÉ CRESPO Y CASTILLO"
    },
    {
        idDistrito: "100605",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "LUYANDO"
    },
    {
        idDistrito: "100606",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "MARIANO DAMASO BERAUN"
    },
    {
        idDistrito: "100607",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "PUCAYACU"
    },
    {
        idDistrito: "100608",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "CASTILLO GRANDE"
    },
    {
        idDistrito: "100609",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "PUEBLO NUEVO"
    },
    {
        idDistrito: "100610",
        idProvincia: "1006",
        idDepartamento: "10",
        nombre: "SANTO DOMINGO DE ANDA"
    },
    {
        idDistrito: "100701",
        idProvincia: "1007",
        idDepartamento: "10",
        nombre: "HUACRACHUCO"
    },
    {
        idDistrito: "100702",
        idProvincia: "1007",
        idDepartamento: "10",
        nombre: "CHOLON"
    },
    {
        idDistrito: "100703",
        idProvincia: "1007",
        idDepartamento: "10",
        nombre: "SAN BUENAVENTURA"
    },
    {
        idDistrito: "100704",
        idProvincia: "1007",
        idDepartamento: "10",
        nombre: "LA MORADA"
    },
    {
        idDistrito: "100705",
        idProvincia: "1007",
        idDepartamento: "10",
        nombre: "SANTA ROSA DE ALTO YANAJANCA"
    },
    {
        idDistrito: "100801",
        idProvincia: "1008",
        idDepartamento: "10",
        nombre: "PANAO"
    },
    {
        idDistrito: "100802",
        idProvincia: "1008",
        idDepartamento: "10",
        nombre: "CHAGLLA"
    },
    {
        idDistrito: "100803",
        idProvincia: "1008",
        idDepartamento: "10",
        nombre: "MOLINO"
    },
    {
        idDistrito: "100804",
        idProvincia: "1008",
        idDepartamento: "10",
        nombre: "UMARI"
    },
    {
        idDistrito: "100901",
        idProvincia: "1009",
        idDepartamento: "10",
        nombre: "PUERTO INCA"
    },
    {
        idDistrito: "100902",
        idProvincia: "1009",
        idDepartamento: "10",
        nombre: "CODO DEL POZUZO"
    },
    {
        idDistrito: "100903",
        idProvincia: "1009",
        idDepartamento: "10",
        nombre: "HONORIA"
    },
    {
        idDistrito: "100904",
        idProvincia: "1009",
        idDepartamento: "10",
        nombre: "TOURNAVISTA"
    },
    {
        idDistrito: "100905",
        idProvincia: "1009",
        idDepartamento: "10",
        nombre: "YUYAPICHIS"
    },
    {
        idDistrito: "101001",
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "JESÚS"
    },
    {
        idDistrito: "101002",
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "BAÑOS"
    },
    {
        idDistrito: "101003",
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "JIVIA"
    },
    {
        idDistrito: "101004",
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "QUEROPALCA"
    },
    {
        idDistrito: "101005",
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "RONDOS"
    },
    {
        idDistrito: "101006",
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "SAN FRANCISCO DE ASÍS"
    },
    {
        idDistrito: "101007",
        idProvincia: "1010",
        idDepartamento: "10",
        nombre: "SAN MIGUEL DE CAURI"
    },
    {
        idDistrito: "101101",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "CHAVINILLO"
    },
    {
        idDistrito: "101102",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "CAHUAC"
    },
    {
        idDistrito: "101103",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "CHACABAMBA"
    },
    {
        idDistrito: "101104",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "APARICIO POMARES"
    },
    {
        idDistrito: "101105",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "JACAS CHICO"
    },
    {
        idDistrito: "101106",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "OBAS"
    },
    {
        idDistrito: "101107",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "PAMPAMARCA"
    },
    {
        idDistrito: "101108",
        idProvincia: "1011",
        idDepartamento: "10",
        nombre: "CHORAS"
    },
    {
        idDistrito: "110101",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "ICA"
    },
    {
        idDistrito: "110102",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "LA TINGUIÑA"
    },
    {
        idDistrito: "110103",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "LOS AQUIJES"
    },
    {
        idDistrito: "110104",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "OCUCAJE"
    },
    {
        idDistrito: "110105",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "PACHACUTEC"
    },
    {
        idDistrito: "110106",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "PARCONA"
    },
    {
        idDistrito: "110107",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "PUEBLO NUEVO"
    },
    {
        idDistrito: "110108",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "SALAS"
    },
    {
        idDistrito: "110109",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "SAN JOSÉ DE LOS MOLINOS"
    },
    {
        idDistrito: "110110",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "SAN JUAN BAUTISTA"
    },
    {
        idDistrito: "110111",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "SANTIAGO"
    },
    {
        idDistrito: "110112",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "SUBTANJALLA"
    },
    {
        idDistrito: "110113",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "TATE"
    },
    {
        idDistrito: "110114",
        idProvincia: "1101",
        idDepartamento: "11",
        nombre: "YAUCA DEL ROSARIO"
    },
    {
        idDistrito: "110201",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "CHINCHA ALTA"
    },
    {
        idDistrito: "110202",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "ALTO LARAN"
    },
    {
        idDistrito: "110203",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "CHAVIN"
    },
    {
        idDistrito: "110204",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "CHINCHA BAJA"
    },
    {
        idDistrito: "110205",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "EL CARMEN"
    },
    {
        idDistrito: "110206",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "GROCIO PRADO"
    },
    {
        idDistrito: "110207",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "PUEBLO NUEVO"
    },
    {
        idDistrito: "110208",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "SAN JUAN DE YANAC"
    },
    {
        idDistrito: "110209",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "SAN PEDRO DE HUACARPANA"
    },
    {
        idDistrito: "110210",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "SUNAMPE"
    },
    {
        idDistrito: "110211",
        idProvincia: "1102",
        idDepartamento: "11",
        nombre: "TAMBO DE MORA"
    },
    {
        idDistrito: "110301",
        idProvincia: "1103",
        idDepartamento: "11",
        nombre: "NASCA"
    },
    {
        idDistrito: "110302",
        idProvincia: "1103",
        idDepartamento: "11",
        nombre: "CHANGUILLO"
    },
    {
        idDistrito: "110303",
        idProvincia: "1103",
        idDepartamento: "11",
        nombre: "EL INGENIO"
    },
    {
        idDistrito: "110304",
        idProvincia: "1103",
        idDepartamento: "11",
        nombre: "MARCONA"
    },
    {
        idDistrito: "110305",
        idProvincia: "1103",
        idDepartamento: "11",
        nombre: "VISTA ALEGRE"
    },
    {
        idDistrito: "110401",
        idProvincia: "1104",
        idDepartamento: "11",
        nombre: "PALPA"
    },
    {
        idDistrito: "110402",
        idProvincia: "1104",
        idDepartamento: "11",
        nombre: "LLIPATA"
    },
    {
        idDistrito: "110403",
        idProvincia: "1104",
        idDepartamento: "11",
        nombre: "RÍO GRANDE"
    },
    {
        idDistrito: "110404",
        idProvincia: "1104",
        idDepartamento: "11",
        nombre: "SANTA CRUZ"
    },
    {
        idDistrito: "110405",
        idProvincia: "1104",
        idDepartamento: "11",
        nombre: "TIBILLO"
    },
    {
        idDistrito: "110501",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "PISCO"
    },
    {
        idDistrito: "110502",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "HUANCANO"
    },
    {
        idDistrito: "110503",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "HUMAY"
    },
    {
        idDistrito: "110504",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "INDEPENDENCIA"
    },
    {
        idDistrito: "110505",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "PARACAS"
    },
    {
        idDistrito: "110506",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "SAN ANDRÉS"
    },
    {
        idDistrito: "110507",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "SAN CLEMENTE"
    },
    {
        idDistrito: "110508",
        idProvincia: "1105",
        idDepartamento: "11",
        nombre: "TUPAC AMARU INCA"
    },
    {
        idDistrito: "120101",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "HUANCAYO"
    },
    {
        idDistrito: "120104",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "CARHUACALLANGA"
    },
    {
        idDistrito: "120105",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "CHACAPAMPA"
    },
    {
        idDistrito: "120106",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "CHICCHE"
    },
    {
        idDistrito: "120107",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "CHILCA"
    },
    {
        idDistrito: "120108",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "CHONGOS ALTO"
    },
    {
        idDistrito: "120111",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "CHUPURO"
    },
    {
        idDistrito: "120112",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "COLCA"
    },
    {
        idDistrito: "120113",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "CULLHUAS"
    },
    {
        idDistrito: "120114",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "EL TAMBO"
    },
    {
        idDistrito: "120116",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "HUACRAPUQUIO"
    },
    {
        idDistrito: "120117",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "HUALHUAS"
    },
    {
        idDistrito: "120119",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "HUANCAN"
    },
    {
        idDistrito: "120120",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "HUASICANCHA"
    },
    {
        idDistrito: "120121",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "HUAYUCACHI"
    },
    {
        idDistrito: "120122",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "INGENIO"
    },
    {
        idDistrito: "120124",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "PARIAHUANCA"
    },
    {
        idDistrito: "120125",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "PILCOMAYO"
    },
    {
        idDistrito: "120126",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "PUCARA"
    },
    {
        idDistrito: "120127",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "QUICHUAY"
    },
    {
        idDistrito: "120128",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "QUILCAS"
    },
    {
        idDistrito: "120129",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "SAN AGUSTÍN"
    },
    {
        idDistrito: "120130",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "SAN JERÓNIMO DE TUNAN"
    },
    {
        idDistrito: "120132",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "SAÑO"
    },
    {
        idDistrito: "120133",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "SAPALLANGA"
    },
    {
        idDistrito: "120134",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "SICAYA"
    },
    {
        idDistrito: "120135",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "SANTO DOMINGO DE ACOBAMBA"
    },
    {
        idDistrito: "120136",
        idProvincia: "1201",
        idDepartamento: "12",
        nombre: "VIQUES"
    },
    {
        idDistrito: "120201",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "CONCEPCIÓN"
    },
    {
        idDistrito: "120202",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "ACO"
    },
    {
        idDistrito: "120203",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "ANDAMARCA"
    },
    {
        idDistrito: "120204",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "CHAMBARA"
    },
    {
        idDistrito: "120205",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "COCHAS"
    },
    {
        idDistrito: "120206",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "COMAS"
    },
    {
        idDistrito: "120207",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "HEROÍNAS TOLEDO"
    },
    {
        idDistrito: "120208",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "MANZANARES"
    },
    {
        idDistrito: "120209",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "MARISCAL CASTILLA"
    },
    {
        idDistrito: "120210",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "MATAHUASI"
    },
    {
        idDistrito: "120211",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "MITO"
    },
    {
        idDistrito: "120212",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "NUEVE DE JULIO"
    },
    {
        idDistrito: "120213",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "ORCOTUNA"
    },
    {
        idDistrito: "120214",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "SAN JOSÉ DE QUERO"
    },
    {
        idDistrito: "120215",
        idProvincia: "1202",
        idDepartamento: "12",
        nombre: "SANTA ROSA DE OCOPA"
    },
    {
        idDistrito: "120301",
        idProvincia: "1203",
        idDepartamento: "12",
        nombre: "CHANCHAMAYO"
    },
    {
        idDistrito: "120302",
        idProvincia: "1203",
        idDepartamento: "12",
        nombre: "PERENE"
    },
    {
        idDistrito: "120303",
        idProvincia: "1203",
        idDepartamento: "12",
        nombre: "PICHANAQUI"
    },
    {
        idDistrito: "120304",
        idProvincia: "1203",
        idDepartamento: "12",
        nombre: "SAN LUIS DE SHUARO"
    },
    {
        idDistrito: "120305",
        idProvincia: "1203",
        idDepartamento: "12",
        nombre: "SAN RAMÓN"
    },
    {
        idDistrito: "120306",
        idProvincia: "1203",
        idDepartamento: "12",
        nombre: "VITOC"
    },
    {
        idDistrito: "120401",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "JAUJA"
    },
    {
        idDistrito: "120402",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "ACOLLA"
    },
    {
        idDistrito: "120403",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "APATA"
    },
    {
        idDistrito: "120404",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "ATAURA"
    },
    {
        idDistrito: "120405",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "CANCHAYLLO"
    },
    {
        idDistrito: "120406",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "CURICACA"
    },
    {
        idDistrito: "120407",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "EL MANTARO"
    },
    {
        idDistrito: "120408",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "HUAMALI"
    },
    {
        idDistrito: "120409",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "HUARIPAMPA"
    },
    {
        idDistrito: "120410",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "HUERTAS"
    },
    {
        idDistrito: "120411",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "JANJAILLO"
    },
    {
        idDistrito: "120412",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "JULCÁN"
    },
    {
        idDistrito: "120413",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "LEONOR ORDÓÑEZ"
    },
    {
        idDistrito: "120414",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "LLOCLLAPAMPA"
    },
    {
        idDistrito: "120415",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "MARCO"
    },
    {
        idDistrito: "120416",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "MASMA"
    },
    {
        idDistrito: "120417",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "MASMA CHICCHE"
    },
    {
        idDistrito: "120418",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "MOLINOS"
    },
    {
        idDistrito: "120419",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "MONOBAMBA"
    },
    {
        idDistrito: "120420",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "MUQUI"
    },
    {
        idDistrito: "120421",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "MUQUIYAUYO"
    },
    {
        idDistrito: "120422",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "PACA"
    },
    {
        idDistrito: "120423",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "PACCHA"
    },
    {
        idDistrito: "120424",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "PANCAN"
    },
    {
        idDistrito: "120425",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "PARCO"
    },
    {
        idDistrito: "120426",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "POMACANCHA"
    },
    {
        idDistrito: "120427",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "RICRAN"
    },
    {
        idDistrito: "120428",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "SAN LORENZO"
    },
    {
        idDistrito: "120429",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "SAN PEDRO DE CHUNAN"
    },
    {
        idDistrito: "120430",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "SAUSA"
    },
    {
        idDistrito: "120431",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "SINCOS"
    },
    {
        idDistrito: "120432",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "TUNAN MARCA"
    },
    {
        idDistrito: "120433",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "YAULI"
    },
    {
        idDistrito: "120434",
        idProvincia: "1204",
        idDepartamento: "12",
        nombre: "YAUYOS"
    },
    {
        idDistrito: "120501",
        idProvincia: "1205",
        idDepartamento: "12",
        nombre: "JUNIN"
    },
    {
        idDistrito: "120502",
        idProvincia: "1205",
        idDepartamento: "12",
        nombre: "CARHUAMAYO"
    },
    {
        idDistrito: "120503",
        idProvincia: "1205",
        idDepartamento: "12",
        nombre: "ONDORES"
    },
    {
        idDistrito: "120504",
        idProvincia: "1205",
        idDepartamento: "12",
        nombre: "ULCUMAYO"
    },
    {
        idDistrito: "120601",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "SATIPO"
    },
    {
        idDistrito: "120602",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "COVIRIALI"
    },
    {
        idDistrito: "120603",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "LLAYLLA"
    },
    {
        idDistrito: "120604",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "MAZAMARI"
    },
    {
        idDistrito: "120605",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "PAMPA HERMOSA"
    },
    {
        idDistrito: "120606",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "PANGOA"
    },
    {
        idDistrito: "120607",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "RÍO NEGRO"
    },
    {
        idDistrito: "120608",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "RÍO TAMBO"
    },
    {
        idDistrito: "120609",
        idProvincia: "1206",
        idDepartamento: "12",
        nombre: "VIZCATAN DEL ENE"
    },
    {
        idDistrito: "120701",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "TARMA"
    },
    {
        idDistrito: "120702",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "ACOBAMBA"
    },
    {
        idDistrito: "120703",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "HUARICOLCA"
    },
    {
        idDistrito: "120704",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "HUASAHUASI"
    },
    {
        idDistrito: "120705",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "LA UNIÓN"
    },
    {
        idDistrito: "120706",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "PALCA"
    },
    {
        idDistrito: "120707",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "PALCAMAYO"
    },
    {
        idDistrito: "120708",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "SAN PEDRO DE CAJAS"
    },
    {
        idDistrito: "120709",
        idProvincia: "1207",
        idDepartamento: "12",
        nombre: "TAPO"
    },
    {
        idDistrito: "120801",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "LA OROYA"
    },
    {
        idDistrito: "120802",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "CHACAPALPA"
    },
    {
        idDistrito: "120803",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "HUAY-HUAY"
    },
    {
        idDistrito: "120804",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "MARCAPOMACOCHA"
    },
    {
        idDistrito: "120805",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "MOROCOCHA"
    },
    {
        idDistrito: "120806",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "PACCHA"
    },
    {
        idDistrito: "120807",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "SANTA BÁRBARA DE CARHUACAYAN"
    },
    {
        idDistrito: "120808",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "SANTA ROSA DE SACCO"
    },
    {
        idDistrito: "120809",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "SUITUCANCHA"
    },
    {
        idDistrito: "120810",
        idProvincia: "1208",
        idDepartamento: "12",
        nombre: "YAULI"
    },
    {
        idDistrito: "120901",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "CHUPACA"
    },
    {
        idDistrito: "120902",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "AHUAC"
    },
    {
        idDistrito: "120903",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "CHONGOS BAJO"
    },
    {
        idDistrito: "120904",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "HUACHAC"
    },
    {
        idDistrito: "120905",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "HUAMANCACA CHICO"
    },
    {
        idDistrito: "120906",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "SAN JUAN DE ISCOS"
    },
    {
        idDistrito: "120907",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "SAN JUAN DE JARPA"
    },
    {
        idDistrito: "120908",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "TRES DE DICIEMBRE"
    },
    {
        idDistrito: "120909",
        idProvincia: "1209",
        idDepartamento: "12",
        nombre: "YANACANCHA"
    },
    {
        idDistrito: "130101",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "TRUJILLO"
    },
    {
        idDistrito: "130102",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "EL PORVENIR"
    },
    {
        idDistrito: "130103",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "FLORENCIA DE MORA"
    },
    {
        idDistrito: "130104",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "HUANCHACO"
    },
    {
        idDistrito: "130105",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "LA ESPERANZA"
    },
    {
        idDistrito: "130106",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "LAREDO"
    },
    {
        idDistrito: "130107",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "MOCHE"
    },
    {
        idDistrito: "130108",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "POROTO"
    },
    {
        idDistrito: "130109",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "SALAVERRY"
    },
    {
        idDistrito: "130110",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "SIMBAL"
    },
    {
        idDistrito: "130111",
        idProvincia: "1301",
        idDepartamento: "13",
        nombre: "VICTOR LARCO HERRERA"
    },
    {
        idDistrito: "130201",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "ASCOPE"
    },
    {
        idDistrito: "130202",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "CHICAMA"
    },
    {
        idDistrito: "130203",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "CHOCOPE"
    },
    {
        idDistrito: "130204",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "MAGDALENA DE CAO"
    },
    {
        idDistrito: "130205",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "PAIJAN"
    },
    {
        idDistrito: "130206",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "RÁZURI"
    },
    {
        idDistrito: "130207",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "SANTIAGO DE CAO"
    },
    {
        idDistrito: "130208",
        idProvincia: "1302",
        idDepartamento: "13",
        nombre: "CASA GRANDE"
    },
    {
        idDistrito: "130301",
        idProvincia: "1303",
        idDepartamento: "13",
        nombre: "BOLÍVAR"
    },
    {
        idDistrito: "130302",
        idProvincia: "1303",
        idDepartamento: "13",
        nombre: "BAMBAMARCA"
    },
    {
        idDistrito: "130303",
        idProvincia: "1303",
        idDepartamento: "13",
        nombre: "CONDORMARCA"
    },
    {
        idDistrito: "130304",
        idProvincia: "1303",
        idDepartamento: "13",
        nombre: "LONGOTEA"
    },
    {
        idDistrito: "130305",
        idProvincia: "1303",
        idDepartamento: "13",
        nombre: "UCHUMARCA"
    },
    {
        idDistrito: "130306",
        idProvincia: "1303",
        idDepartamento: "13",
        nombre: "UCUNCHA"
    },
    {
        idDistrito: "130401",
        idProvincia: "1304",
        idDepartamento: "13",
        nombre: "CHEPEN"
    },
    {
        idDistrito: "130402",
        idProvincia: "1304",
        idDepartamento: "13",
        nombre: "PACANGA"
    },
    {
        idDistrito: "130403",
        idProvincia: "1304",
        idDepartamento: "13",
        nombre: "PUEBLO NUEVO"
    },
    {
        idDistrito: "130501",
        idProvincia: "1305",
        idDepartamento: "13",
        nombre: "JULCAN"
    },
    {
        idDistrito: "130502",
        idProvincia: "1305",
        idDepartamento: "13",
        nombre: "CALAMARCA"
    },
    {
        idDistrito: "130503",
        idProvincia: "1305",
        idDepartamento: "13",
        nombre: "CARABAMBA"
    },
    {
        idDistrito: "130504",
        idProvincia: "1305",
        idDepartamento: "13",
        nombre: "HUASO"
    },
    {
        idDistrito: "130601",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "OTUZCO"
    },
    {
        idDistrito: "130602",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "AGALLPAMPA"
    },
    {
        idDistrito: "130604",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "CHARAT"
    },
    {
        idDistrito: "130605",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "HUARANCHAL"
    },
    {
        idDistrito: "130606",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "LA CUESTA"
    },
    {
        idDistrito: "130608",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "MACHE"
    },
    {
        idDistrito: "130610",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "PARANDAY"
    },
    {
        idDistrito: "130611",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "SALPO"
    },
    {
        idDistrito: "130613",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "SINSICAP"
    },
    {
        idDistrito: "130614",
        idProvincia: "1306",
        idDepartamento: "13",
        nombre: "USQUIL"
    },
    {
        idDistrito: "130701",
        idProvincia: "1307",
        idDepartamento: "13",
        nombre: "SAN PEDRO DE LLOC"
    },
    {
        idDistrito: "130702",
        idProvincia: "1307",
        idDepartamento: "13",
        nombre: "GUADALUPE"
    },
    {
        idDistrito: "130703",
        idProvincia: "1307",
        idDepartamento: "13",
        nombre: "JEQUETEPEQUE"
    },
    {
        idDistrito: "130704",
        idProvincia: "1307",
        idDepartamento: "13",
        nombre: "PACASMAYO"
    },
    {
        idDistrito: "130705",
        idProvincia: "1307",
        idDepartamento: "13",
        nombre: "SAN JOSÉ"
    },
    {
        idDistrito: "130801",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "TAYABAMBA"
    },
    {
        idDistrito: "130802",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "BULDIBUYO"
    },
    {
        idDistrito: "130803",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "CHILLIA"
    },
    {
        idDistrito: "130804",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "HUANCASPATA"
    },
    {
        idDistrito: "130805",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "HUAYLILLAS"
    },
    {
        idDistrito: "130806",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "HUAYO"
    },
    {
        idDistrito: "130807",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "ONGON"
    },
    {
        idDistrito: "130808",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "PARCOY"
    },
    {
        idDistrito: "130809",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "PATAZ"
    },
    {
        idDistrito: "130810",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "PIAS"
    },
    {
        idDistrito: "130811",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "SANTIAGO DE CHALLAS"
    },
    {
        idDistrito: "130812",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "TAURIJA"
    },
    {
        idDistrito: "130813",
        idProvincia: "1308",
        idDepartamento: "13",
        nombre: "URPAY"
    },
    {
        idDistrito: "130901",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "HUAMACHUCO"
    },
    {
        idDistrito: "130902",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "CHUGAY"
    },
    {
        idDistrito: "130903",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "COCHORCO"
    },
    {
        idDistrito: "130904",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "CURGOS"
    },
    {
        idDistrito: "130905",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "MARCABAL"
    },
    {
        idDistrito: "130906",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "SANAGORAN"
    },
    {
        idDistrito: "130907",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "SARIN"
    },
    {
        idDistrito: "130908",
        idProvincia: "1309",
        idDepartamento: "13",
        nombre: "SARTIMBAMBA"
    },
    {
        idDistrito: "131001",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "SANTIAGO DE CHUCO"
    },
    {
        idDistrito: "131002",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "ANGASMARCA"
    },
    {
        idDistrito: "131003",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "CACHICADAN"
    },
    {
        idDistrito: "131004",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "MOLLEBAMBA"
    },
    {
        idDistrito: "131005",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "MOLLEPATA"
    },
    {
        idDistrito: "131006",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "QUIRUVILCA"
    },
    {
        idDistrito: "131007",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "SANTA CRUZ DE CHUCA"
    },
    {
        idDistrito: "131008",
        idProvincia: "1310",
        idDepartamento: "13",
        nombre: "SITABAMBA"
    },
    {
        idDistrito: "131101",
        idProvincia: "1311",
        idDepartamento: "13",
        nombre: "CASCAS"
    },
    {
        idDistrito: "131102",
        idProvincia: "1311",
        idDepartamento: "13",
        nombre: "LUCMA"
    },
    {
        idDistrito: "131103",
        idProvincia: "1311",
        idDepartamento: "13",
        nombre: "MARMOT"
    },
    {
        idDistrito: "131104",
        idProvincia: "1311",
        idDepartamento: "13",
        nombre: "SAYAPULLO"
    },
    {
        idDistrito: "131201",
        idProvincia: "1312",
        idDepartamento: "13",
        nombre: "VIRU"
    },
    {
        idDistrito: "131202",
        idProvincia: "1312",
        idDepartamento: "13",
        nombre: "CHAO"
    },
    {
        idDistrito: "131203",
        idProvincia: "1312",
        idDepartamento: "13",
        nombre: "GUADALUPITO"
    },
    {
        idDistrito: "140101",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "CHICLAYO"
    },
    {
        idDistrito: "140102",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "CHONGOYAPE"
    },
    {
        idDistrito: "140103",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "ETEN"
    },
    {
        idDistrito: "140104",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "ETEN PUERTO"
    },
    {
        idDistrito: "140105",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "JOSÉ LEONARDO ORTIZ"
    },
    {
        idDistrito: "140106",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "LA VICTORIA"
    },
    {
        idDistrito: "140107",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "LAGUNAS"
    },
    {
        idDistrito: "140108",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "MONSEFU"
    },
    {
        idDistrito: "140109",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "NUEVA ARICA"
    },
    {
        idDistrito: "140110",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "OYOTUN"
    },
    {
        idDistrito: "140111",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "PICSI"
    },
    {
        idDistrito: "140112",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "PIMENTEL"
    },
    {
        idDistrito: "140113",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "REQUE"
    },
    {
        idDistrito: "140114",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "140115",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "SAÑA"
    },
    {
        idDistrito: "140116",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "CAYALTI"
    },
    {
        idDistrito: "140117",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "PATAPO"
    },
    {
        idDistrito: "140118",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "POMALCA"
    },
    {
        idDistrito: "140119",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "PUCALA"
    },
    {
        idDistrito: "140120",
        idProvincia: "1401",
        idDepartamento: "14",
        nombre: "TUMAN"
    },
    {
        idDistrito: "140201",
        idProvincia: "1402",
        idDepartamento: "14",
        nombre: "FERREÑAFE"
    },
    {
        idDistrito: "140202",
        idProvincia: "1402",
        idDepartamento: "14",
        nombre: "CAÑARIS"
    },
    {
        idDistrito: "140203",
        idProvincia: "1402",
        idDepartamento: "14",
        nombre: "INCAHUASI"
    },
    {
        idDistrito: "140204",
        idProvincia: "1402",
        idDepartamento: "14",
        nombre: "MANUEL ANTONIO MESONES MURO"
    },
    {
        idDistrito: "140205",
        idProvincia: "1402",
        idDepartamento: "14",
        nombre: "PITIPO"
    },
    {
        idDistrito: "140206",
        idProvincia: "1402",
        idDepartamento: "14",
        nombre: "PUEBLO NUEVO"
    },
    {
        idDistrito: "140301",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "LAMBAYEQUE"
    },
    {
        idDistrito: "140302",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "CHOCHOPE"
    },
    {
        idDistrito: "140303",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "ILLIMO"
    },
    {
        idDistrito: "140304",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "JAYANCA"
    },
    {
        idDistrito: "140305",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "MOCHUMI"
    },
    {
        idDistrito: "140306",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "MORROPE"
    },
    {
        idDistrito: "140307",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "MOTUPE"
    },
    {
        idDistrito: "140308",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "OLMOS"
    },
    {
        idDistrito: "140309",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "PACORA"
    },
    {
        idDistrito: "140310",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "SALAS"
    },
    {
        idDistrito: "140311",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "SAN JOSÉ"
    },
    {
        idDistrito: "140312",
        idProvincia: "1403",
        idDepartamento: "14",
        nombre: "TUCUME"
    },
    {
        idDistrito: "150101",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LIMA"
    },
    {
        idDistrito: "150102",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "ANCÓN"
    },
    {
        idDistrito: "150103",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "ATE"
    },
    {
        idDistrito: "150104",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "BARRANCO"
    },
    {
        idDistrito: "150105",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "BREÑA"
    },
    {
        idDistrito: "150106",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "CARABAYLLO"
    },
    {
        idDistrito: "150107",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "CHACLACAYO"
    },
    {
        idDistrito: "150108",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "CHORRILLOS"
    },
    {
        idDistrito: "150109",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "CIENEGUILLA"
    },
    {
        idDistrito: "150110",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "COMAS"
    },
    {
        idDistrito: "150111",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "EL AGUSTINO"
    },
    {
        idDistrito: "150112",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "INDEPENDENCIA"
    },
    {
        idDistrito: "150113",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "JESÚS MARÍA"
    },
    {
        idDistrito: "150114",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LA MOLINA"
    },
    {
        idDistrito: "150115",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LA VICTORIA"
    },
    {
        idDistrito: "150116",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LINCE"
    },
    {
        idDistrito: "150117",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LOS OLIVOS"
    },
    {
        idDistrito: "150118",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LURIGANCHO"
    },
    {
        idDistrito: "150119",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "LURIN"
    },
    {
        idDistrito: "150120",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "MAGDALENA DEL MAR"
    },
    {
        idDistrito: "150121",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "PUEBLO LIBRE"
    },
    {
        idDistrito: "150122",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "MIRAFLORES"
    },
    {
        idDistrito: "150123",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "PACHACAMAC"
    },
    {
        idDistrito: "150124",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "PUCUSANA"
    },
    {
        idDistrito: "150125",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "PUENTE PIEDRA"
    },
    {
        idDistrito: "150126",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "PUNTA HERMOSA"
    },
    {
        idDistrito: "150127",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "PUNTA NEGRA"
    },
    {
        idDistrito: "150128",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "RÍMAC"
    },
    {
        idDistrito: "150129",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN BARTOLO"
    },
    {
        idDistrito: "150130",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN BORJA"
    },
    {
        idDistrito: "150131",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN ISIDRO"
    },
    {
        idDistrito: "150132",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN JUAN DE LURIGANCHO"
    },
    {
        idDistrito: "150133",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN JUAN DE MIRAFLORES"
    },
    {
        idDistrito: "150134",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN LUIS"
    },
    {
        idDistrito: "150135",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN MARTÍN DE PORRES"
    },
    {
        idDistrito: "150136",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SAN MIGUEL"
    },
    {
        idDistrito: "150137",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SANTA ANITA"
    },
    {
        idDistrito: "150138",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SANTA MARÍA DEL MAR"
    },
    {
        idDistrito: "150139",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "150140",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SANTIAGO DE SURCO"
    },
    {
        idDistrito: "150141",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "SURQUILLO"
    },
    {
        idDistrito: "150142",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "VILLA EL SALVADOR"
    },
    {
        idDistrito: "150143",
        idProvincia: "1501",
        idDepartamento: "15",
        nombre: "VILLA MARÍA DEL TRIUNFO"
    },
    {
        idDistrito: "150201",
        idProvincia: "1502",
        idDepartamento: "15",
        nombre: "BARRANCA"
    },
    {
        idDistrito: "150202",
        idProvincia: "1502",
        idDepartamento: "15",
        nombre: "PARAMONGA"
    },
    {
        idDistrito: "150203",
        idProvincia: "1502",
        idDepartamento: "15",
        nombre: "PATIVILCA"
    },
    {
        idDistrito: "150204",
        idProvincia: "1502",
        idDepartamento: "15",
        nombre: "SUPE"
    },
    {
        idDistrito: "150205",
        idProvincia: "1502",
        idDepartamento: "15",
        nombre: "SUPE PUERTO"
    },
    {
        idDistrito: "150301",
        idProvincia: "1503",
        idDepartamento: "15",
        nombre: "CAJATAMBO"
    },
    {
        idDistrito: "150302",
        idProvincia: "1503",
        idDepartamento: "15",
        nombre: "COPA"
    },
    {
        idDistrito: "150303",
        idProvincia: "1503",
        idDepartamento: "15",
        nombre: "GORGOR"
    },
    {
        idDistrito: "150304",
        idProvincia: "1503",
        idDepartamento: "15",
        nombre: "HUANCAPON"
    },
    {
        idDistrito: "150305",
        idProvincia: "1503",
        idDepartamento: "15",
        nombre: "MANAS"
    },
    {
        idDistrito: "150401",
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "CANTA"
    },
    {
        idDistrito: "150402",
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "ARAHUAY"
    },
    {
        idDistrito: "150403",
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "HUAMANTANGA"
    },
    {
        idDistrito: "150404",
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "HUAROS"
    },
    {
        idDistrito: "150405",
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "LACHAQUI"
    },
    {
        idDistrito: "150406",
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "SAN BUENAVENTURA"
    },
    {
        idDistrito: "150407",
        idProvincia: "1504",
        idDepartamento: "15",
        nombre: "SANTA ROSA DE QUIVES"
    },
    {
        idDistrito: "150501",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "SAN VICENTE DE CAÑETE"
    },
    {
        idDistrito: "150502",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "ASIA"
    },
    {
        idDistrito: "150503",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "CALANGO"
    },
    {
        idDistrito: "150504",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "CERRO AZUL"
    },
    {
        idDistrito: "150505",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "CHILCA"
    },
    {
        idDistrito: "150506",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "COAYLLO"
    },
    {
        idDistrito: "150507",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "IMPERIAL"
    },
    {
        idDistrito: "150508",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "LUNAHUANA"
    },
    {
        idDistrito: "150509",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "MALA"
    },
    {
        idDistrito: "150510",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "NUEVO IMPERIAL"
    },
    {
        idDistrito: "150511",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "PACARAN"
    },
    {
        idDistrito: "150512",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "QUILMANA"
    },
    {
        idDistrito: "150513",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "SAN ANTONIO"
    },
    {
        idDistrito: "150514",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "SAN LUIS"
    },
    {
        idDistrito: "150515",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "SANTA CRUZ DE FLORES"
    },
    {
        idDistrito: "150516",
        idProvincia: "1505",
        idDepartamento: "15",
        nombre: "ZÚÑIGA"
    },
    {
        idDistrito: "150601",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "HUARAL"
    },
    {
        idDistrito: "150602",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "ATAVILLOS ALTO"
    },
    {
        idDistrito: "150603",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "ATAVILLOS BAJO"
    },
    {
        idDistrito: "150604",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "AUCALLAMA"
    },
    {
        idDistrito: "150605",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "CHANCAY"
    },
    {
        idDistrito: "150606",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "IHUARI"
    },
    {
        idDistrito: "150607",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "LAMPIAN"
    },
    {
        idDistrito: "150608",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "PACARAOS"
    },
    {
        idDistrito: "150609",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "SAN MIGUEL DE ACOS"
    },
    {
        idDistrito: "150610",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "SANTA CRUZ DE ANDAMARCA"
    },
    {
        idDistrito: "150611",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "SUMBILCA"
    },
    {
        idDistrito: "150612",
        idProvincia: "1506",
        idDepartamento: "15",
        nombre: "VEINTISIETE DE NOVIEMBRE"
    },
    {
        idDistrito: "150701",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "MATUCANA"
    },
    {
        idDistrito: "150702",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "ANTIOQUIA"
    },
    {
        idDistrito: "150703",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "CALLAHUANCA"
    },
    {
        idDistrito: "150704",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "CARAMPOMA"
    },
    {
        idDistrito: "150705",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "CHICLA"
    },
    {
        idDistrito: "150706",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "CUENCA"
    },
    {
        idDistrito: "150707",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "HUACHUPAMPA"
    },
    {
        idDistrito: "150708",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "HUANZA"
    },
    {
        idDistrito: "150709",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "HUAROCHIRI"
    },
    {
        idDistrito: "150710",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "LAHUAYTAMBO"
    },
    {
        idDistrito: "150711",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "LANGA"
    },
    {
        idDistrito: "150712",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "LARAOS"
    },
    {
        idDistrito: "150713",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "MARIATANA"
    },
    {
        idDistrito: "150714",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "RICARDO PALMA"
    },
    {
        idDistrito: "150715",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN ANDRÉS DE TUPICOCHA"
    },
    {
        idDistrito: "150716",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN ANTONIO"
    },
    {
        idDistrito: "150717",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN BARTOLOMÉ"
    },
    {
        idDistrito: "150718",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN DAMIAN"
    },
    {
        idDistrito: "150719",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN JUAN DE IRIS"
    },
    {
        idDistrito: "150720",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN JUAN DE TANTARANCHE"
    },
    {
        idDistrito: "150721",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN LORENZO DE QUINTI"
    },
    {
        idDistrito: "150722",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN MATEO"
    },
    {
        idDistrito: "150723",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN MATEO DE OTAO"
    },
    {
        idDistrito: "150724",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN PEDRO DE CASTA"
    },
    {
        idDistrito: "150725",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SAN PEDRO DE HUANCAYRE"
    },
    {
        idDistrito: "150726",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SANGALLAYA"
    },
    {
        idDistrito: "150727",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SANTA CRUZ DE COCACHACRA"
    },
    {
        idDistrito: "150728",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SANTA EULALIA"
    },
    {
        idDistrito: "150729",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SANTIAGO DE ANCHUCAYA"
    },
    {
        idDistrito: "150730",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SANTIAGO DE TUNA"
    },
    {
        idDistrito: "150731",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SANTO DOMINGO DE LOS OLLEROS"
    },
    {
        idDistrito: "150732",
        idProvincia: "1507",
        idDepartamento: "15",
        nombre: "SURCO"
    },
    {
        idDistrito: "150801",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "HUACHO"
    },
    {
        idDistrito: "150802",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "AMBAR"
    },
    {
        idDistrito: "150803",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "CALETA DE CARQUIN"
    },
    {
        idDistrito: "150804",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "CHECRAS"
    },
    {
        idDistrito: "150805",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "HUALMAY"
    },
    {
        idDistrito: "150806",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "HUAURA"
    },
    {
        idDistrito: "150807",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "LEONCIO PRADO"
    },
    {
        idDistrito: "150808",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "PACCHO"
    },
    {
        idDistrito: "150809",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "SANTA LEONOR"
    },
    {
        idDistrito: "150810",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "SANTA MARÍA"
    },
    {
        idDistrito: "150811",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "SAYAN"
    },
    {
        idDistrito: "150812",
        idProvincia: "1508",
        idDepartamento: "15",
        nombre: "VEGUETA"
    },
    {
        idDistrito: "150901",
        idProvincia: "1509",
        idDepartamento: "15",
        nombre: "OYON"
    },
    {
        idDistrito: "150902",
        idProvincia: "1509",
        idDepartamento: "15",
        nombre: "ANDAJES"
    },
    {
        idDistrito: "150903",
        idProvincia: "1509",
        idDepartamento: "15",
        nombre: "CAUJUL"
    },
    {
        idDistrito: "150904",
        idProvincia: "1509",
        idDepartamento: "15",
        nombre: "COCHAMARCA"
    },
    {
        idDistrito: "150905",
        idProvincia: "1509",
        idDepartamento: "15",
        nombre: "NAVAN"
    },
    {
        idDistrito: "150906",
        idProvincia: "1509",
        idDepartamento: "15",
        nombre: "PACHANGARA"
    },
    {
        idDistrito: "151001",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "YAUYOS"
    },
    {
        idDistrito: "151002",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "ALIS"
    },
    {
        idDistrito: "151003",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "ALLAUCA"
    },
    {
        idDistrito: "151004",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "AYAVIRI"
    },
    {
        idDistrito: "151005",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "AZÁNGARO"
    },
    {
        idDistrito: "151006",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "CACRA"
    },
    {
        idDistrito: "151007",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "CARANIA"
    },
    {
        idDistrito: "151008",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "CATAHUASI"
    },
    {
        idDistrito: "151009",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "CHOCOS"
    },
    {
        idDistrito: "151010",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "COCHAS"
    },
    {
        idDistrito: "151011",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "COLONIA"
    },
    {
        idDistrito: "151012",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "HONGOS"
    },
    {
        idDistrito: "151013",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "HUAMPARA"
    },
    {
        idDistrito: "151014",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "HUANCAYA"
    },
    {
        idDistrito: "151015",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "HUANGASCAR"
    },
    {
        idDistrito: "151016",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "HUANTAN"
    },
    {
        idDistrito: "151017",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "HUAÑEC"
    },
    {
        idDistrito: "151018",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "LARAOS"
    },
    {
        idDistrito: "151019",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "LINCHA"
    },
    {
        idDistrito: "151020",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "MADEAN"
    },
    {
        idDistrito: "151021",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "MIRAFLORES"
    },
    {
        idDistrito: "151022",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "OMAS"
    },
    {
        idDistrito: "151023",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "PUTINZA"
    },
    {
        idDistrito: "151024",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "QUINCHES"
    },
    {
        idDistrito: "151025",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "QUINOCAY"
    },
    {
        idDistrito: "151026",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "SAN JOAQUÍN"
    },
    {
        idDistrito: "151027",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "SAN PEDRO DE PILAS"
    },
    {
        idDistrito: "151028",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "TANTA"
    },
    {
        idDistrito: "151029",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "TAURIPAMPA"
    },
    {
        idDistrito: "151030",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "TOMAS"
    },
    {
        idDistrito: "151031",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "TUPE"
    },
    {
        idDistrito: "151032",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "VIÑAC"
    },
    {
        idDistrito: "151033",
        idProvincia: "1510",
        idDepartamento: "15",
        nombre: "VITIS"
    },
    {
        idDistrito: "160101",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "IQUITOS"
    },
    {
        idDistrito: "160102",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "ALTO NANAY"
    },
    {
        idDistrito: "160103",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "FERNANDO LORES"
    },
    {
        idDistrito: "160104",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "INDIANA"
    },
    {
        idDistrito: "160105",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "LAS AMAZONAS"
    },
    {
        idDistrito: "160106",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "MAZAN"
    },
    {
        idDistrito: "160107",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "NAPO"
    },
    {
        idDistrito: "160108",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "PUNCHANA"
    },
    {
        idDistrito: "160110",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "TORRES CAUSANA"
    },
    {
        idDistrito: "160112",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "BELÉN"
    },
    {
        idDistrito: "160113",
        idProvincia: "1601",
        idDepartamento: "16",
        nombre: "SAN JUAN BAUTISTA"
    },
    {
        idDistrito: "160201",
        idProvincia: "1602",
        idDepartamento: "16",
        nombre: "YURIMAGUAS"
    },
    {
        idDistrito: "160202",
        idProvincia: "1602",
        idDepartamento: "16",
        nombre: "BALSAPUERTO"
    },
    {
        idDistrito: "160205",
        idProvincia: "1602",
        idDepartamento: "16",
        nombre: "JEBEROS"
    },
    {
        idDistrito: "160206",
        idProvincia: "1602",
        idDepartamento: "16",
        nombre: "LAGUNAS"
    },
    {
        idDistrito: "160210",
        idProvincia: "1602",
        idDepartamento: "16",
        nombre: "SANTA CRUZ"
    },
    {
        idDistrito: "160211",
        idProvincia: "1602",
        idDepartamento: "16",
        nombre: "TENIENTE CESAR LÓPEZ ROJAS"
    },
    {
        idDistrito: "160301",
        idProvincia: "1603",
        idDepartamento: "16",
        nombre: "NAUTA"
    },
    {
        idDistrito: "160302",
        idProvincia: "1603",
        idDepartamento: "16",
        nombre: "PARINARI"
    },
    {
        idDistrito: "160303",
        idProvincia: "1603",
        idDepartamento: "16",
        nombre: "TIGRE"
    },
    {
        idDistrito: "160304",
        idProvincia: "1603",
        idDepartamento: "16",
        nombre: "TROMPETEROS"
    },
    {
        idDistrito: "160305",
        idProvincia: "1603",
        idDepartamento: "16",
        nombre: "URARINAS"
    },
    {
        idDistrito: "160401",
        idProvincia: "1604",
        idDepartamento: "16",
        nombre: "RAMÓN CASTILLA"
    },
    {
        idDistrito: "160402",
        idProvincia: "1604",
        idDepartamento: "16",
        nombre: "PEBAS"
    },
    {
        idDistrito: "160403",
        idProvincia: "1604",
        idDepartamento: "16",
        nombre: "YAVARI"
    },
    {
        idDistrito: "160404",
        idProvincia: "1604",
        idDepartamento: "16",
        nombre: "SAN PABLO"
    },
    {
        idDistrito: "160501",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "REQUENA"
    },
    {
        idDistrito: "160502",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "ALTO TAPICHE"
    },
    {
        idDistrito: "160503",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "CAPELO"
    },
    {
        idDistrito: "160504",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "EMILIO SAN MARTÍN"
    },
    {
        idDistrito: "160505",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "MAQUIA"
    },
    {
        idDistrito: "160506",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "PUINAHUA"
    },
    {
        idDistrito: "160507",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "SAQUENA"
    },
    {
        idDistrito: "160508",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "SOPLIN"
    },
    {
        idDistrito: "160509",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "TAPICHE"
    },
    {
        idDistrito: "160510",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "JENARO HERRERA"
    },
    {
        idDistrito: "160511",
        idProvincia: "1605",
        idDepartamento: "16",
        nombre: "YAQUERANA"
    },
    {
        idDistrito: "160601",
        idProvincia: "1606",
        idDepartamento: "16",
        nombre: "CONTAMANA"
    },
    {
        idDistrito: "160602",
        idProvincia: "1606",
        idDepartamento: "16",
        nombre: "INAHUAYA"
    },
    {
        idDistrito: "160603",
        idProvincia: "1606",
        idDepartamento: "16",
        nombre: "PADRE MÁRQUEZ"
    },
    {
        idDistrito: "160604",
        idProvincia: "1606",
        idDepartamento: "16",
        nombre: "PAMPA HERMOSA"
    },
    {
        idDistrito: "160605",
        idProvincia: "1606",
        idDepartamento: "16",
        nombre: "SARAYACU"
    },
    {
        idDistrito: "160606",
        idProvincia: "1606",
        idDepartamento: "16",
        nombre: "VARGAS GUERRA"
    },
    {
        idDistrito: "160701",
        idProvincia: "1607",
        idDepartamento: "16",
        nombre: "BARRANCA"
    },
    {
        idDistrito: "160702",
        idProvincia: "1607",
        idDepartamento: "16",
        nombre: "CAHUAPANAS"
    },
    {
        idDistrito: "160703",
        idProvincia: "1607",
        idDepartamento: "16",
        nombre: "MANSERICHE"
    },
    {
        idDistrito: "160704",
        idProvincia: "1607",
        idDepartamento: "16",
        nombre: "MORONA"
    },
    {
        idDistrito: "160705",
        idProvincia: "1607",
        idDepartamento: "16",
        nombre: "PASTAZA"
    },
    {
        idDistrito: "160706",
        idProvincia: "1607",
        idDepartamento: "16",
        nombre: "ANDOAS"
    },
    {
        idDistrito: "160801",
        idProvincia: "1608",
        idDepartamento: "16",
        nombre: "PUTUMAYO"
    },
    {
        idDistrito: "160802",
        idProvincia: "1608",
        idDepartamento: "16",
        nombre: "ROSA PANDURO"
    },
    {
        idDistrito: "160803",
        idProvincia: "1608",
        idDepartamento: "16",
        nombre: "TENIENTE MANUEL CLAVERO"
    },
    {
        idDistrito: "160804",
        idProvincia: "1608",
        idDepartamento: "16",
        nombre: "YAGUAS"
    },
    {
        idDistrito: "170101",
        idProvincia: "1701",
        idDepartamento: "17",
        nombre: "TAMBOPATA"
    },
    {
        idDistrito: "170102",
        idProvincia: "1701",
        idDepartamento: "17",
        nombre: "INAMBARI"
    },
    {
        idDistrito: "170103",
        idProvincia: "1701",
        idDepartamento: "17",
        nombre: "LAS PIEDRAS"
    },
    {
        idDistrito: "170104",
        idProvincia: "1701",
        idDepartamento: "17",
        nombre: "LABERINTO"
    },
    {
        idDistrito: "170201",
        idProvincia: "1702",
        idDepartamento: "17",
        nombre: "MANU"
    },
    {
        idDistrito: "170202",
        idProvincia: "1702",
        idDepartamento: "17",
        nombre: "FITZCARRALD"
    },
    {
        idDistrito: "170203",
        idProvincia: "1702",
        idDepartamento: "17",
        nombre: "MADRE DE DIOS"
    },
    {
        idDistrito: "170204",
        idProvincia: "1702",
        idDepartamento: "17",
        nombre: "HUEPETUHE"
    },
    {
        idDistrito: "170301",
        idProvincia: "1703",
        idDepartamento: "17",
        nombre: "IÑAPARI"
    },
    {
        idDistrito: "170302",
        idProvincia: "1703",
        idDepartamento: "17",
        nombre: "IBERIA"
    },
    {
        idDistrito: "170303",
        idProvincia: "1703",
        idDepartamento: "17",
        nombre: "TAHUAMANU"
    },
    {
        idDistrito: "180101",
        idProvincia: "1801",
        idDepartamento: "18",
        nombre: "MOQUEGUA"
    },
    {
        idDistrito: "180102",
        idProvincia: "1801",
        idDepartamento: "18",
        nombre: "CARUMAS"
    },
    {
        idDistrito: "180103",
        idProvincia: "1801",
        idDepartamento: "18",
        nombre: "CUCHUMBAYA"
    },
    {
        idDistrito: "180104",
        idProvincia: "1801",
        idDepartamento: "18",
        nombre: "SAMEGUA"
    },
    {
        idDistrito: "180105",
        idProvincia: "1801",
        idDepartamento: "18",
        nombre: "SAN CRISTÓBAL"
    },
    {
        idDistrito: "180106",
        idProvincia: "1801",
        idDepartamento: "18",
        nombre: "TORATA"
    },
    {
        idDistrito: "180201",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "OMATE"
    },
    {
        idDistrito: "180202",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "CHOJATA"
    },
    {
        idDistrito: "180203",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "COALAQUE"
    },
    {
        idDistrito: "180204",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "ICHUÑA"
    },
    {
        idDistrito: "180205",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "LA CAPILLA"
    },
    {
        idDistrito: "180206",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "LLOQUE"
    },
    {
        idDistrito: "180207",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "MATALAQUE"
    },
    {
        idDistrito: "180208",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "PUQUINA"
    },
    {
        idDistrito: "180209",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "QUINISTAQUILLAS"
    },
    {
        idDistrito: "180210",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "UBINAS"
    },
    {
        idDistrito: "180211",
        idProvincia: "1802",
        idDepartamento: "18",
        nombre: "YUNGA"
    },
    {
        idDistrito: "180301",
        idProvincia: "1803",
        idDepartamento: "18",
        nombre: "ILO"
    },
    {
        idDistrito: "180302",
        idProvincia: "1803",
        idDepartamento: "18",
        nombre: "EL ALGARROBAL"
    },
    {
        idDistrito: "180303",
        idProvincia: "1803",
        idDepartamento: "18",
        nombre: "PACOCHA"
    },
    {
        idDistrito: "190101",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "CHAUPIMARCA"
    },
    {
        idDistrito: "190102",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "HUACHON"
    },
    {
        idDistrito: "190103",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "HUARIACA"
    },
    {
        idDistrito: "190104",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "HUAYLLAY"
    },
    {
        idDistrito: "190105",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "NINACACA"
    },
    {
        idDistrito: "190106",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "PALLANCHACRA"
    },
    {
        idDistrito: "190107",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "PAUCARTAMBO"
    },
    {
        idDistrito: "190108",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "SAN FRANCISCO DE ASÍS DE YARUSYACAN"
    },
    {
        idDistrito: "190109",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "SIMON BOLÍVAR"
    },
    {
        idDistrito: "190110",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "TICLACAYAN"
    },
    {
        idDistrito: "190111",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "TINYAHUARCO"
    },
    {
        idDistrito: "190112",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "VICCO"
    },
    {
        idDistrito: "190113",
        idProvincia: "1901",
        idDepartamento: "19",
        nombre: "YANACANCHA"
    },
    {
        idDistrito: "190201",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "YANAHUANCA"
    },
    {
        idDistrito: "190202",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "CHACAYAN"
    },
    {
        idDistrito: "190203",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "GOYLLARISQUIZGA"
    },
    {
        idDistrito: "190204",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "PAUCAR"
    },
    {
        idDistrito: "190205",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "SAN PEDRO DE PILLAO"
    },
    {
        idDistrito: "190206",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "SANTA ANA DE TUSI"
    },
    {
        idDistrito: "190207",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "TAPUC"
    },
    {
        idDistrito: "190208",
        idProvincia: "1902",
        idDepartamento: "19",
        nombre: "VILCABAMBA"
    },
    {
        idDistrito: "190301",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "OXAPAMPA"
    },
    {
        idDistrito: "190302",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "CHONTABAMBA"
    },
    {
        idDistrito: "190303",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "HUANCABAMBA"
    },
    {
        idDistrito: "190304",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "PALCAZU"
    },
    {
        idDistrito: "190305",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "POZUZO"
    },
    {
        idDistrito: "190306",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "PUERTO BERMÚDEZ"
    },
    {
        idDistrito: "190307",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "VILLA RICA"
    },
    {
        idDistrito: "190308",
        idProvincia: "1903",
        idDepartamento: "19",
        nombre: "CONSTITUCIÓN"
    },
    {
        idDistrito: "200101",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "PIURA"
    },
    {
        idDistrito: "200104",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "CASTILLA"
    },
    {
        idDistrito: "200105",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "CATACAOS"
    },
    {
        idDistrito: "200107",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "CURA MORI"
    },
    {
        idDistrito: "200108",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "EL TALLAN"
    },
    {
        idDistrito: "200109",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "LA ARENA"
    },
    {
        idDistrito: "200110",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "LA UNIÓN"
    },
    {
        idDistrito: "200111",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "LAS LOMAS"
    },
    {
        idDistrito: "200114",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "TAMBO GRANDE"
    },
    {
        idDistrito: "200115",
        idProvincia: "2001",
        idDepartamento: "20",
        nombre: "VEINTISEIS DE OCTUBRE"
    },
    {
        idDistrito: "200201",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "AYABACA"
    },
    {
        idDistrito: "200202",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "FRIAS"
    },
    {
        idDistrito: "200203",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "JILILI"
    },
    {
        idDistrito: "200204",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "LAGUNAS"
    },
    {
        idDistrito: "200205",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "MONTERO"
    },
    {
        idDistrito: "200206",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "PACAIPAMPA"
    },
    {
        idDistrito: "200207",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "PAIMAS"
    },
    {
        idDistrito: "200208",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "SAPILLICA"
    },
    {
        idDistrito: "200209",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "SICCHEZ"
    },
    {
        idDistrito: "200210",
        idProvincia: "2002",
        idDepartamento: "20",
        nombre: "SUYO"
    },
    {
        idDistrito: "200301",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "HUANCABAMBA"
    },
    {
        idDistrito: "200302",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "CANCHAQUE"
    },
    {
        idDistrito: "200303",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "EL CARMEN DE LA FRONTERA"
    },
    {
        idDistrito: "200304",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "HUARMACA"
    },
    {
        idDistrito: "200305",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "LALAQUIZ"
    },
    {
        idDistrito: "200306",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "SAN MIGUEL DE EL FAIQUE"
    },
    {
        idDistrito: "200307",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "SONDOR"
    },
    {
        idDistrito: "200308",
        idProvincia: "2003",
        idDepartamento: "20",
        nombre: "SONDORILLO"
    },
    {
        idDistrito: "200401",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "CHULUCANAS"
    },
    {
        idDistrito: "200402",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "BUENOS AIRES"
    },
    {
        idDistrito: "200403",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "CHALACO"
    },
    {
        idDistrito: "200404",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "LA MATANZA"
    },
    {
        idDistrito: "200405",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "MORROPON"
    },
    {
        idDistrito: "200406",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "SALITRAL"
    },
    {
        idDistrito: "200407",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "SAN JUAN DE BIGOTE"
    },
    {
        idDistrito: "200408",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "SANTA CATALINA DE MOSSA"
    },
    {
        idDistrito: "200409",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "SANTO DOMINGO"
    },
    {
        idDistrito: "200410",
        idProvincia: "2004",
        idDepartamento: "20",
        nombre: "YAMANGO"
    },
    {
        idDistrito: "200501",
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "PAITA"
    },
    {
        idDistrito: "200502",
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "AMOTAPE"
    },
    {
        idDistrito: "200503",
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "ARENAL"
    },
    {
        idDistrito: "200504",
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "COLAN"
    },
    {
        idDistrito: "200505",
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "LA HUACA"
    },
    {
        idDistrito: "200506",
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "TAMARINDO"
    },
    {
        idDistrito: "200507",
        idProvincia: "2005",
        idDepartamento: "20",
        nombre: "VICHAYAL"
    },
    {
        idDistrito: "200601",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "SULLANA"
    },
    {
        idDistrito: "200602",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "BELLAVISTA"
    },
    {
        idDistrito: "200603",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "IGNACIO ESCUDERO"
    },
    {
        idDistrito: "200604",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "LANCONES"
    },
    {
        idDistrito: "200605",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "MARCAVELICA"
    },
    {
        idDistrito: "200606",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "MIGUEL CHECA"
    },
    {
        idDistrito: "200607",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "QUERECOTILLO"
    },
    {
        idDistrito: "200608",
        idProvincia: "2006",
        idDepartamento: "20",
        nombre: "SALITRAL"
    },
    {
        idDistrito: "200701",
        idProvincia: "2007",
        idDepartamento: "20",
        nombre: "PARIÑAS"
    },
    {
        idDistrito: "200702",
        idProvincia: "2007",
        idDepartamento: "20",
        nombre: "EL ALTO"
    },
    {
        idDistrito: "200703",
        idProvincia: "2007",
        idDepartamento: "20",
        nombre: "LA BREA"
    },
    {
        idDistrito: "200704",
        idProvincia: "2007",
        idDepartamento: "20",
        nombre: "LOBITOS"
    },
    {
        idDistrito: "200705",
        idProvincia: "2007",
        idDepartamento: "20",
        nombre: "LOS ORGANOS"
    },
    {
        idDistrito: "200706",
        idProvincia: "2007",
        idDepartamento: "20",
        nombre: "MANCORA"
    },
    {
        idDistrito: "200801",
        idProvincia: "2008",
        idDepartamento: "20",
        nombre: "SECHURA"
    },
    {
        idDistrito: "200802",
        idProvincia: "2008",
        idDepartamento: "20",
        nombre: "BELLAVISTA DE LA UNIÓN"
    },
    {
        idDistrito: "200803",
        idProvincia: "2008",
        idDepartamento: "20",
        nombre: "BERNAL"
    },
    {
        idDistrito: "200804",
        idProvincia: "2008",
        idDepartamento: "20",
        nombre: "CRISTO NOS VALGA"
    },
    {
        idDistrito: "200805",
        idProvincia: "2008",
        idDepartamento: "20",
        nombre: "VICE"
    },
    {
        idDistrito: "200806",
        idProvincia: "2008",
        idDepartamento: "20",
        nombre: "RINCONADA LLICUAR"
    },
    {
        idDistrito: "210101",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "PUNO"
    },
    {
        idDistrito: "210102",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "ACORA"
    },
    {
        idDistrito: "210103",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "AMANTANI"
    },
    {
        idDistrito: "210104",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "ATUNCOLLA"
    },
    {
        idDistrito: "210105",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "CAPACHICA"
    },
    {
        idDistrito: "210106",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "CHUCUITO"
    },
    {
        idDistrito: "210107",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "COATA"
    },
    {
        idDistrito: "210108",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "HUATA"
    },
    {
        idDistrito: "210109",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "MAÑAZO"
    },
    {
        idDistrito: "210110",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "PAUCARCOLLA"
    },
    {
        idDistrito: "210111",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "PICHACANI"
    },
    {
        idDistrito: "210112",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "PLATERIA"
    },
    {
        idDistrito: "210113",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "SAN ANTONIO"
    },
    {
        idDistrito: "210114",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "TIQUILLACA"
    },
    {
        idDistrito: "210115",
        idProvincia: "2101",
        idDepartamento: "21",
        nombre: "VILQUE"
    },
    {
        idDistrito: "210201",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "AZÁNGARO"
    },
    {
        idDistrito: "210202",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "ACHAYA"
    },
    {
        idDistrito: "210203",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "ARAPA"
    },
    {
        idDistrito: "210204",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "ASILLO"
    },
    {
        idDistrito: "210205",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "CAMINACA"
    },
    {
        idDistrito: "210206",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "CHUPA"
    },
    {
        idDistrito: "210207",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "JOSÉ DOMINGO CHOQUEHUANCA"
    },
    {
        idDistrito: "210208",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "MUÑANI"
    },
    {
        idDistrito: "210209",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "POTONI"
    },
    {
        idDistrito: "210210",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "SAMAN"
    },
    {
        idDistrito: "210211",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "SAN ANTON"
    },
    {
        idDistrito: "210212",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "SAN JOSÉ"
    },
    {
        idDistrito: "210213",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "SAN JUAN DE SALINAS"
    },
    {
        idDistrito: "210214",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "SANTIAGO DE PUPUJA"
    },
    {
        idDistrito: "210215",
        idProvincia: "2102",
        idDepartamento: "21",
        nombre: "TIRAPATA"
    },
    {
        idDistrito: "210301",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "MACUSANI"
    },
    {
        idDistrito: "210302",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "AJOYANI"
    },
    {
        idDistrito: "210303",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "AYAPATA"
    },
    {
        idDistrito: "210304",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "COASA"
    },
    {
        idDistrito: "210305",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "CORANI"
    },
    {
        idDistrito: "210306",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "CRUCERO"
    },
    {
        idDistrito: "210307",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "ITUATA"
    },
    {
        idDistrito: "210308",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "OLLACHEA"
    },
    {
        idDistrito: "210309",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "SAN GABAN"
    },
    {
        idDistrito: "210310",
        idProvincia: "2103",
        idDepartamento: "21",
        nombre: "USICAYOS"
    },
    {
        idDistrito: "210401",
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "JULI"
    },
    {
        idDistrito: "210402",
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "DESAGUADERO"
    },
    {
        idDistrito: "210403",
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "HUACULLANI"
    },
    {
        idDistrito: "210404",
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "KELLUYO"
    },
    {
        idDistrito: "210405",
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "PISACOMA"
    },
    {
        idDistrito: "210406",
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "POMATA"
    },
    {
        idDistrito: "210407",
        idProvincia: "2104",
        idDepartamento: "21",
        nombre: "ZEPITA"
    },
    {
        idDistrito: "210501",
        idProvincia: "2105",
        idDepartamento: "21",
        nombre: "ILAVE"
    },
    {
        idDistrito: "210502",
        idProvincia: "2105",
        idDepartamento: "21",
        nombre: "CAPAZO"
    },
    {
        idDistrito: "210503",
        idProvincia: "2105",
        idDepartamento: "21",
        nombre: "PILCUYO"
    },
    {
        idDistrito: "210504",
        idProvincia: "2105",
        idDepartamento: "21",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "210505",
        idProvincia: "2105",
        idDepartamento: "21",
        nombre: "CONDURIRI"
    },
    {
        idDistrito: "210601",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "HUANCANE"
    },
    {
        idDistrito: "210602",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "COJATA"
    },
    {
        idDistrito: "210603",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "HUATASANI"
    },
    {
        idDistrito: "210604",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "INCHUPALLA"
    },
    {
        idDistrito: "210605",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "PUSI"
    },
    {
        idDistrito: "210606",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "ROSASPATA"
    },
    {
        idDistrito: "210607",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "TARACO"
    },
    {
        idDistrito: "210608",
        idProvincia: "2106",
        idDepartamento: "21",
        nombre: "VILQUE CHICO"
    },
    {
        idDistrito: "210701",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "LAMPA"
    },
    {
        idDistrito: "210702",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "CABANILLA"
    },
    {
        idDistrito: "210703",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "CALAPUJA"
    },
    {
        idDistrito: "210704",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "NICASIO"
    },
    {
        idDistrito: "210705",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "OCUVIRI"
    },
    {
        idDistrito: "210706",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "PALCA"
    },
    {
        idDistrito: "210707",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "PARATIA"
    },
    {
        idDistrito: "210708",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "PUCARA"
    },
    {
        idDistrito: "210709",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "SANTA LUCIA"
    },
    {
        idDistrito: "210710",
        idProvincia: "2107",
        idDepartamento: "21",
        nombre: "VILAVILA"
    },
    {
        idDistrito: "210801",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "AYAVIRI"
    },
    {
        idDistrito: "210802",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "ANTAUTA"
    },
    {
        idDistrito: "210803",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "CUPI"
    },
    {
        idDistrito: "210804",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "LLALLI"
    },
    {
        idDistrito: "210805",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "MACARI"
    },
    {
        idDistrito: "210806",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "NUÑOA"
    },
    {
        idDistrito: "210807",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "ORURILLO"
    },
    {
        idDistrito: "210808",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "210809",
        idProvincia: "2108",
        idDepartamento: "21",
        nombre: "UMACHIRI"
    },
    {
        idDistrito: "210901",
        idProvincia: "2109",
        idDepartamento: "21",
        nombre: "MOHO"
    },
    {
        idDistrito: "210902",
        idProvincia: "2109",
        idDepartamento: "21",
        nombre: "CONIMA"
    },
    {
        idDistrito: "210903",
        idProvincia: "2109",
        idDepartamento: "21",
        nombre: "HUAYRAPATA"
    },
    {
        idDistrito: "210904",
        idProvincia: "2109",
        idDepartamento: "21",
        nombre: "TILALI"
    },
    {
        idDistrito: "211001",
        idProvincia: "2110",
        idDepartamento: "21",
        nombre: "PUTINA"
    },
    {
        idDistrito: "211002",
        idProvincia: "2110",
        idDepartamento: "21",
        nombre: "ANANEA"
    },
    {
        idDistrito: "211003",
        idProvincia: "2110",
        idDepartamento: "21",
        nombre: "PEDRO VILCA APAZA"
    },
    {
        idDistrito: "211004",
        idProvincia: "2110",
        idDepartamento: "21",
        nombre: "QUILCAPUNCU"
    },
    {
        idDistrito: "211005",
        idProvincia: "2110",
        idDepartamento: "21",
        nombre: "SINA"
    },
    {
        idDistrito: "211101",
        idProvincia: "2111",
        idDepartamento: "21",
        nombre: "JULIACA"
    },
    {
        idDistrito: "211102",
        idProvincia: "2111",
        idDepartamento: "21",
        nombre: "CABANA"
    },
    {
        idDistrito: "211103",
        idProvincia: "2111",
        idDepartamento: "21",
        nombre: "CABANILLAS"
    },
    {
        idDistrito: "211104",
        idProvincia: "2111",
        idDepartamento: "21",
        nombre: "CARACOTO"
    },
    {
        idDistrito: "211105",
        idProvincia: "2111",
        idDepartamento: "21",
        nombre: "SAN MIGUEL"
    },
    {
        idDistrito: "211201",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "SANDIA"
    },
    {
        idDistrito: "211202",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "CUYOCUYO"
    },
    {
        idDistrito: "211203",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "LIMBANI"
    },
    {
        idDistrito: "211204",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "PATAMBUCO"
    },
    {
        idDistrito: "211205",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "PHARA"
    },
    {
        idDistrito: "211206",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "QUIACA"
    },
    {
        idDistrito: "211207",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "SAN JUAN DEL ORO"
    },
    {
        idDistrito: "211208",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "YANAHUAYA"
    },
    {
        idDistrito: "211209",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "ALTO INAMBARI"
    },
    {
        idDistrito: "211210",
        idProvincia: "2112",
        idDepartamento: "21",
        nombre: "SAN PEDRO DE PUTINA PUNCO"
    },
    {
        idDistrito: "211301",
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "YUNGUYO"
    },
    {
        idDistrito: "211302",
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "ANAPIA"
    },
    {
        idDistrito: "211303",
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "COPANI"
    },
    {
        idDistrito: "211304",
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "CUTURAPI"
    },
    {
        idDistrito: "211305",
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "OLLARAYA"
    },
    {
        idDistrito: "211306",
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "TINICACHI"
    },
    {
        idDistrito: "211307",
        idProvincia: "2113",
        idDepartamento: "21",
        nombre: "UNICACHI"
    },
    {
        idDistrito: "220101",
        idProvincia: "2201",
        idDepartamento: "22",
        nombre: "MOYOBAMBA"
    },
    {
        idDistrito: "220102",
        idProvincia: "2201",
        idDepartamento: "22",
        nombre: "CALZADA"
    },
    {
        idDistrito: "220103",
        idProvincia: "2201",
        idDepartamento: "22",
        nombre: "HABANA"
    },
    {
        idDistrito: "220104",
        idProvincia: "2201",
        idDepartamento: "22",
        nombre: "JEPELACIO"
    },
    {
        idDistrito: "220105",
        idProvincia: "2201",
        idDepartamento: "22",
        nombre: "SORITOR"
    },
    {
        idDistrito: "220106",
        idProvincia: "2201",
        idDepartamento: "22",
        nombre: "YANTALO"
    },
    {
        idDistrito: "220201",
        idProvincia: "2202",
        idDepartamento: "22",
        nombre: "BELLAVISTA"
    },
    {
        idDistrito: "220202",
        idProvincia: "2202",
        idDepartamento: "22",
        nombre: "ALTO BIAVO"
    },
    {
        idDistrito: "220203",
        idProvincia: "2202",
        idDepartamento: "22",
        nombre: "BAJO BIAVO"
    },
    {
        idDistrito: "220204",
        idProvincia: "2202",
        idDepartamento: "22",
        nombre: "HUALLAGA"
    },
    {
        idDistrito: "220205",
        idProvincia: "2202",
        idDepartamento: "22",
        nombre: "SAN PABLO"
    },
    {
        idDistrito: "220206",
        idProvincia: "2202",
        idDepartamento: "22",
        nombre: "SAN RAFAEL"
    },
    {
        idDistrito: "220301",
        idProvincia: "2203",
        idDepartamento: "22",
        nombre: "SAN JOSÉ DE SISA"
    },
    {
        idDistrito: "220302",
        idProvincia: "2203",
        idDepartamento: "22",
        nombre: "AGUA BLANCA"
    },
    {
        idDistrito: "220303",
        idProvincia: "2203",
        idDepartamento: "22",
        nombre: "SAN MARTÍN"
    },
    {
        idDistrito: "220304",
        idProvincia: "2203",
        idDepartamento: "22",
        nombre: "SANTA ROSA"
    },
    {
        idDistrito: "220305",
        idProvincia: "2203",
        idDepartamento: "22",
        nombre: "SHATOJA"
    },
    {
        idDistrito: "220401",
        idProvincia: "2204",
        idDepartamento: "22",
        nombre: "SAPOSOA"
    },
    {
        idDistrito: "220402",
        idProvincia: "2204",
        idDepartamento: "22",
        nombre: "ALTO SAPOSOA"
    },
    {
        idDistrito: "220403",
        idProvincia: "2204",
        idDepartamento: "22",
        nombre: "EL ESLABÓN"
    },
    {
        idDistrito: "220404",
        idProvincia: "2204",
        idDepartamento: "22",
        nombre: "PISCOYACU"
    },
    {
        idDistrito: "220405",
        idProvincia: "2204",
        idDepartamento: "22",
        nombre: "SACANCHE"
    },
    {
        idDistrito: "220406",
        idProvincia: "2204",
        idDepartamento: "22",
        nombre: "TINGO DE SAPOSOA"
    },
    {
        idDistrito: "220501",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "LAMAS"
    },
    {
        idDistrito: "220502",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "ALONSO DE ALVARADO"
    },
    {
        idDistrito: "220503",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "BARRANQUITA"
    },
    {
        idDistrito: "220504",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "CAYNARACHI"
    },
    {
        idDistrito: "220505",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "CUÑUMBUQUI"
    },
    {
        idDistrito: "220506",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "PINTO RECODO"
    },
    {
        idDistrito: "220507",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "RUMISAPA"
    },
    {
        idDistrito: "220508",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "SAN ROQUE DE CUMBAZA"
    },
    {
        idDistrito: "220509",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "SHANAO"
    },
    {
        idDistrito: "220510",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "TABALOSOS"
    },
    {
        idDistrito: "220511",
        idProvincia: "2205",
        idDepartamento: "22",
        nombre: "ZAPATERO"
    },
    {
        idDistrito: "220601",
        idProvincia: "2206",
        idDepartamento: "22",
        nombre: "JUANJUÍ"
    },
    {
        idDistrito: "220602",
        idProvincia: "2206",
        idDepartamento: "22",
        nombre: "CAMPANILLA"
    },
    {
        idDistrito: "220603",
        idProvincia: "2206",
        idDepartamento: "22",
        nombre: "HUICUNGO"
    },
    {
        idDistrito: "220604",
        idProvincia: "2206",
        idDepartamento: "22",
        nombre: "PACHIZA"
    },
    {
        idDistrito: "220605",
        idProvincia: "2206",
        idDepartamento: "22",
        nombre: "PAJARILLO"
    },
    {
        idDistrito: "220701",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "PICOTA"
    },
    {
        idDistrito: "220702",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "BUENOS AIRES"
    },
    {
        idDistrito: "220703",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "CASPISAPA"
    },
    {
        idDistrito: "220704",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "PILLUANA"
    },
    {
        idDistrito: "220705",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "PUCACACA"
    },
    {
        idDistrito: "220706",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "SAN CRISTÓBAL"
    },
    {
        idDistrito: "220707",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "SAN HILARIÓN"
    },
    {
        idDistrito: "220708",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "SHAMBOYACU"
    },
    {
        idDistrito: "220709",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "TINGO DE PONASA"
    },
    {
        idDistrito: "220710",
        idProvincia: "2207",
        idDepartamento: "22",
        nombre: "TRES UNIDOS"
    },
    {
        idDistrito: "220801",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "RIOJA"
    },
    {
        idDistrito: "220802",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "AWAJUN"
    },
    {
        idDistrito: "220803",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "ELÍAS SOPLIN VARGAS"
    },
    {
        idDistrito: "220804",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "NUEVA CAJAMARCA"
    },
    {
        idDistrito: "220805",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "PARDO MIGUEL"
    },
    {
        idDistrito: "220806",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "POSIC"
    },
    {
        idDistrito: "220807",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "SAN FERNANDO"
    },
    {
        idDistrito: "220808",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "YORONGOS"
    },
    {
        idDistrito: "220809",
        idProvincia: "2208",
        idDepartamento: "22",
        nombre: "YURACYACU"
    },
    {
        idDistrito: "220901",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "TARAPOTO"
    },
    {
        idDistrito: "220902",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "ALBERTO LEVEAU"
    },
    {
        idDistrito: "220903",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "CACATACHI"
    },
    {
        idDistrito: "220904",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "CHAZUTA"
    },
    {
        idDistrito: "220905",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "CHIPURANA"
    },
    {
        idDistrito: "220906",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "EL PORVENIR"
    },
    {
        idDistrito: "220907",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "HUIMBAYOC"
    },
    {
        idDistrito: "220908",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "JUAN GUERRA"
    },
    {
        idDistrito: "220909",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "LA BANDA DE SHILCAYO"
    },
    {
        idDistrito: "220910",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "MORALES"
    },
    {
        idDistrito: "220911",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "PAPAPLAYA"
    },
    {
        idDistrito: "220912",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "SAN ANTONIO"
    },
    {
        idDistrito: "220913",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "SAUCE"
    },
    {
        idDistrito: "220914",
        idProvincia: "2209",
        idDepartamento: "22",
        nombre: "SHAPAJA"
    },
    {
        idDistrito: "221001",
        idProvincia: "2210",
        idDepartamento: "22",
        nombre: "TOCACHE"
    },
    {
        idDistrito: "221002",
        idProvincia: "2210",
        idDepartamento: "22",
        nombre: "NUEVO PROGRESO"
    },
    {
        idDistrito: "221003",
        idProvincia: "2210",
        idDepartamento: "22",
        nombre: "POLVORA"
    },
    {
        idDistrito: "221004",
        idProvincia: "2210",
        idDepartamento: "22",
        nombre: "SHUNTE"
    },
    {
        idDistrito: "221005",
        idProvincia: "2210",
        idDepartamento: "22",
        nombre: "UCHIZA"
    },
    {
        idDistrito: "230101",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "TACNA"
    },
    {
        idDistrito: "230102",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "ALTO DE LA ALIANZA"
    },
    {
        idDistrito: "230103",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "CALANA"
    },
    {
        idDistrito: "230104",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "CIUDAD NUEVA"
    },
    {
        idDistrito: "230105",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "INCLAN"
    },
    {
        idDistrito: "230106",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "PACHIA"
    },
    {
        idDistrito: "230107",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "PALCA"
    },
    {
        idDistrito: "230108",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "POCOLLAY"
    },
    {
        idDistrito: "230109",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "SAMA"
    },
    {
        idDistrito: "230110",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "CORONEL GREGORIO ALBARRACÍN LANCHIPA"
    },
    {
        idDistrito: "230111",
        idProvincia: "2301",
        idDepartamento: "23",
        nombre: "LA YARADA LOS PALOS"
    },
    {
        idDistrito: "230201",
        idProvincia: "2302",
        idDepartamento: "23",
        nombre: "CANDARAVE"
    },
    {
        idDistrito: "230202",
        idProvincia: "2302",
        idDepartamento: "23",
        nombre: "CAIRANI"
    },
    {
        idDistrito: "230203",
        idProvincia: "2302",
        idDepartamento: "23",
        nombre: "CAMILACA"
    },
    {
        idDistrito: "230204",
        idProvincia: "2302",
        idDepartamento: "23",
        nombre: "CURIBAYA"
    },
    {
        idDistrito: "230205",
        idProvincia: "2302",
        idDepartamento: "23",
        nombre: "HUANUARA"
    },
    {
        idDistrito: "230206",
        idProvincia: "2302",
        idDepartamento: "23",
        nombre: "QUILAHUANI"
    },
    {
        idDistrito: "230301",
        idProvincia: "2303",
        idDepartamento: "23",
        nombre: "LOCUMBA"
    },
    {
        idDistrito: "230302",
        idProvincia: "2303",
        idDepartamento: "23",
        nombre: "ILABAYA"
    },
    {
        idDistrito: "230303",
        idProvincia: "2303",
        idDepartamento: "23",
        nombre: "ITE"
    },
    {
        idDistrito: "230401",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "TARATA"
    },
    {
        idDistrito: "230402",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "HÉROES ALBARRACÍN"
    },
    {
        idDistrito: "230403",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "ESTIQUE"
    },
    {
        idDistrito: "230404",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "ESTIQUE-PAMPA"
    },
    {
        idDistrito: "230405",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "SITAJARA"
    },
    {
        idDistrito: "230406",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "SUSAPAYA"
    },
    {
        idDistrito: "230407",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "TARUCACHI"
    },
    {
        idDistrito: "230408",
        idProvincia: "2304",
        idDepartamento: "23",
        nombre: "TICACO"
    },
    {
        idDistrito: "240101",
        idProvincia: "2401",
        idDepartamento: "24",
        nombre: "TUMBES"
    },
    {
        idDistrito: "240102",
        idProvincia: "2401",
        idDepartamento: "24",
        nombre: "CORRALES"
    },
    {
        idDistrito: "240103",
        idProvincia: "2401",
        idDepartamento: "24",
        nombre: "LA CRUZ"
    },
    {
        idDistrito: "240104",
        idProvincia: "2401",
        idDepartamento: "24",
        nombre: "PAMPAS DE HOSPITAL"
    },
    {
        idDistrito: "240105",
        idProvincia: "2401",
        idDepartamento: "24",
        nombre: "SAN JACINTO"
    },
    {
        idDistrito: "240106",
        idProvincia: "2401",
        idDepartamento: "24",
        nombre: "SAN JUAN DE LA VIRGEN"
    },
    {
        idDistrito: "240201",
        idProvincia: "2402",
        idDepartamento: "24",
        nombre: "ZORRITOS"
    },
    {
        idDistrito: "240202",
        idProvincia: "2402",
        idDepartamento: "24",
        nombre: "CASITAS"
    },
    {
        idDistrito: "240203",
        idProvincia: "2402",
        idDepartamento: "24",
        nombre: "CANOAS DE PUNTA SAL"
    },
    {
        idDistrito: "240301",
        idProvincia: "2403",
        idDepartamento: "24",
        nombre: "ZARUMILLA"
    },
    {
        idDistrito: "240302",
        idProvincia: "2403",
        idDepartamento: "24",
        nombre: "AGUAS VERDES"
    },
    {
        idDistrito: "240303",
        idProvincia: "2403",
        idDepartamento: "24",
        nombre: "MATAPALO"
    },
    {
        idDistrito: "240304",
        idProvincia: "2403",
        idDepartamento: "24",
        nombre: "PAPAYAL"
    },
    {
        idDistrito: "250101",
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "CALLERIA"
    },
    {
        idDistrito: "250102",
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "CAMPOVERDE"
    },
    {
        idDistrito: "250103",
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "IPARIA"
    },
    {
        idDistrito: "250104",
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "MASISEA"
    },
    {
        idDistrito: "250105",
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "YARINACOCHA"
    },
    {
        idDistrito: "250106",
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "NUEVA REQUENA"
    },
    {
        idDistrito: "250107",
        idProvincia: "2501",
        idDepartamento: "25",
        nombre: "MANANTAY"
    },
    {
        idDistrito: "250201",
        idProvincia: "2502",
        idDepartamento: "25",
        nombre: "RAYMONDI"
    },
    {
        idDistrito: "250202",
        idProvincia: "2502",
        idDepartamento: "25",
        nombre: "SEPAHUA"
    },
    {
        idDistrito: "250203",
        idProvincia: "2502",
        idDepartamento: "25",
        nombre: "TAHUANIA"
    },
    {
        idDistrito: "250204",
        idProvincia: "2502",
        idDepartamento: "25",
        nombre: "YURUA"
    },
    {
        idDistrito: "250301",
        idProvincia: "2503",
        idDepartamento: "25",
        nombre: "PADRE ABAD"
    },
    {
        idDistrito: "250302",
        idProvincia: "2503",
        idDepartamento: "25",
        nombre: "IRAZOLA"
    },
    {
        idDistrito: "250303",
        idProvincia: "2503",
        idDepartamento: "25",
        nombre: "CURIMANA"
    },
    {
        idDistrito: "250304",
        idProvincia: "2503",
        idDepartamento: "25",
        nombre: "NESHUYA"
    },
    {
        idDistrito: "250305",
        idProvincia: "2503",
        idDepartamento: "25",
        nombre: "ALEXANDER VON HUMBOLDT"
    },
    {
        idDistrito: "250401",
        idProvincia: "2504",
        idDepartamento: "25",
        nombre: "PURUS"
    }
];

export default { Departamentos, Provincias, Distritos };